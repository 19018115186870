// Packages
import { useState } from 'react' 
import clsx from 'clsx'

const SearchBar = ({ 
    className, 
    placeHolderExtra,
    disable,
    onChange,
}: { 
    className?: string;
    placeHolderExtra?: string;
    disable?: boolean;
    onChange?: (value: string) => void;
}) => {
    // States
    const [value, setValue] = useState('')

    return (
        <label className={clsx('input input-bordered flex items-center gap-2', className)}>
            <input 
                disabled={disable ?? false}
                value={value}
                type="text" 
                className={clsx('grow')} 
                placeholder={`Search${placeHolderExtra ? ` (${placeHolderExtra})` : ''}`}
                onChange={(event) => {
                    if (!disable) {
                        setValue(event.target.value)
                        
                        if (!onChange) return
                        onChange(event.target.value)
                    }
                }}
            />
            <svg
                className={clsx('h-4 w-4 opacity-70')}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd" 
                />
            </svg>
        </label>   
    )
}

export default SearchBar