/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
// Contexts
import { UserContext } from "../contexts/user";
import { LoadingBlockerContext } from "../contexts/loadingBlocker";

const Logout = () => {
    // React Router
    const navigate = useNavigate();
    // Contexts
    const [, userDispatch] = useContext<any>(UserContext)
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext)

    useEffect(() => {
        (async () => {
            loadingBlockerDispatch({
                type: 'ADD_BLOCKER',
                payload: 'LOGOUT'
            })
            await signOut();
            userDispatch({ type: 'RESET_USER' });
            loadingBlockerDispatch({
                type: 'REMOVE_BLOCKER',
                payload: 'LOGOUT'
            })
            navigate('/login');
        })()
    }, [])

    return <></>;
};

export default Logout;
