// Packages
import React from 'react';
// Components
import Paths from './components/Paths';
import DisplayLoadingBlocker from './components/DisplayLoadingBlocker';
import DisplayPageBlocker from './components/DisplayPageBlocker';
// Providers
import UserProvider from './contexts/user';
import ClientProvider from './contexts/clients';
import SubscriptionProvider from './contexts/subscription';
import LoadingBlockerProvider from './contexts/loadingBlocker';
import ProductsProvider from './contexts/products';

function App() {
  return (
    <LoadingBlockerProvider>
      <UserProvider>
        <ClientProvider>
          <SubscriptionProvider>
            <ProductsProvider>
              <DisplayLoadingBlocker />
              <DisplayPageBlocker>
                <Paths />
              </DisplayPageBlocker>
            </ProductsProvider>
          </SubscriptionProvider>
        </ClientProvider>
      </UserProvider>
    </LoadingBlockerProvider>
  );
}

export default App;
