// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const DropDown = ({ button, options, keyIdentifier }: { button: ReactNode, options: ReactNode[], keyIdentifier: string }) => {
    return (
        <div className={clsx('dropdown dropdown-end')}>
            <div tabIndex={0} role="button">
                {button}
            </div>
            <ul tabIndex={0} className={clsx('dropdown-content text-black dark:text-white menu bg-base-100 rounded-box z-[1] mt-2 w-52 p-2 shadow dark:shadow-2xl dark:bg-base-200')}>
                {
                    options.map((option, i) => {
                        return <li key={`dropdown-${keyIdentifier}-${i}`}>{option}</li>
                    })
                }
            </ul>
        </div>   
    )
}

export default DropDown