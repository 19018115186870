// Helpers
import initialState from './initialState'

const LoadingBlockerReducer = (state: any, action: { type: String; payload?: any }) => {
    switch (action.type) {
        case 'SET':
            return action.payload
        case 'SET_DEFAULT':
            return initialState
        case 'ADD_BLOCKER':
            return { ...state, blockers: { ...state.blockers, [action.payload]: true } }
        case 'REMOVE_BLOCKER':
            return { ...state, blockers: { ...state.blockers, [action.payload]: false } }
        case 'ADD_PAGE_BLOCKER':
            return { ...state, pageBlockers: { ...state.pageBlockers, [action.payload]: true } }
        case 'REMOVE_PAGE_BLOCKER':
            return { ...state, pageBlockers: { ...state.pageBlockers, [action.payload]: false } }
        default:
            return state
    }
}

export default LoadingBlockerReducer
