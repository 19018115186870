/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const setMtgCardInventoryEntry = /* GraphQL */ `mutation SetMtgCardInventoryEntry($input: MtgCardInventoryEntryInput) {
  setMtgCardInventoryEntry(input: $input) {
    userId
    setCardId
    scryFallId
    oracleId
    multiverseIds
    tcgPlayerId
    cardMarketId
    cardName
    imageUrl
    prices
    colors
    power
    toughness
    manaCost
    cmc
    priceType
    items {
      id
      condition
      priceBought
      dateBought
      isInDeck
      deckId
      __typename
    }
    setId
    setName
    cardId
    legalities
    rarity
    keywords
    type
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetMtgCardInventoryEntryMutationVariables,
  APITypes.SetMtgCardInventoryEntryMutation
>;
export const setMtgCard = /* GraphQL */ `mutation SetMtgCard($input: MtgCardInput) {
  setMtgCard(input: $input) {
    setCardId
    scryFallId
    oracleId
    multiverseIds
    tcgPlayerId
    cardMarketId
    cardName
    imageUrl
    prices
    colors
    power
    toughness
    manaCost
    cmc
    priceType
    setId
    setName
    cardId
    legalities
    rarity
    keywords
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetMtgCardMutationVariables,
  APITypes.SetMtgCardMutation
>;
