// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const TableHead = ({ 
    className,
    children,
    id,
}: { 
    className?: string;
    children: ReactNode;
    id: string;
}) => {
    return (
        <thead className={clsx(className)}>
            <tr>
                {children}
            </tr> 
        </thead> 
    )
}

export default TableHead