// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const TitledText = ({ 
    className, 
    titleClassName,
    textClassName,
    children,
    title
}: { 
    className?: string;
    titleClassName?: string;
    textClassName?: string;
    title: string;
    children: ReactNode;
}) => {
    return (
        <div className={clsx('mx-auto h-fit text-wrap', className)}>
            <h5 className={clsx('text-base font-semibold inline', titleClassName)}>
                {title}:
            </h5>
            &nbsp;
            <p  className={clsx('text-sm inline', textClassName)}>
                {children}
            </p>
        </div>   
    )
}

export default TitledText