/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { post } from 'aws-amplify/api'
import Papa from "papaparse";
// Components
import SubscriptionProtection from "../../components/SubscriptionProtection";
// UI
import Button from "../../ui/Button";
import Loading from "../../ui/Loading";
import HeaderBackground from "../../ui/HeaderBackground";
import Select from "../../ui/Select";
import Modal from "../../ui/Modal";
// Helpers
import { sleep } from "../../helpers";
import { deleteCard, getCards } from "./helpers/mtgCardInventoryBackend";
// Contexts
import { UserContext } from "../../contexts/user";
import { ClientContext } from "../../contexts/clients";

const UploadMtgCardsToInventory = () => {
    // States
    const [csvFile, setCsvFile] = useState('');
    const [toDeleteInventory, setToDeleteInventory] = useState('keep');
    const [isLoading, setIsLoading] = useState(false);
    const [objectAmount, setObjectAmount] = useState(0);
    const [amountOfObjectsCompleted, setAmountOfObjectsCompleted] = useState(0);
    const [erroredFields, setErroredFields] = useState<any>([]);
    // Contexts
    const [user] = useContext<any>(UserContext)
    const [clients] = useContext<any>(ClientContext)

    const deleteInventory = async () => {
        if (toDeleteInventory === 'delete') {
            const cards = await getCards({ userId: user?.userId, clients: clients })
            setObjectAmount((prev: any) => prev + cards?.length)

            for (const card of cards) {
                await deleteCard({
                    clients,
                    userId: user?.userId,
                    setCardId: card?.setCardId,
                })
                setAmountOfObjectsCompleted((prev) => prev + 1)
            }
        }
    }

    const uploadInventory = async () => {
        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                try {
                    setObjectAmount((prev: any) => prev + Object.keys(results?.data ?? []).length)
                    await deleteInventory()
                    for (const data of (results?.data ?? []) as any) {
                        let bodies = []
                        if (data?.setCardId) {
                            let items = JSON.parse(data?.items?.replaceAll("`", '"') ?? '[]')
                            let amountOfItems: any = {}
                            for (const item of (items ?? []) as any) {
                                if (amountOfItems?.[`${data?.setId}#${data?.cardId}#${item?.condition}#${item?.deckId}#${item?.priceBought}#${item?.dateBought}`] > 0) {
                                    amountOfItems[`${data?.setId}#${data?.cardId}#${item?.condition}#${item?.deckId}#${item?.priceBought}#${item?.dateBought}`] = amountOfItems[`${data?.setId}#${data?.cardId}#${item?.condition}#${item?.deckId}#${item?.priceBought}#${item?.dateBought}`] + 1
                                } else {
                                    amountOfItems[`${data?.setId}#${data?.cardId}#${item?.condition}#${item?.deckId}#${item?.priceBought}#${item?.dateBought}`] = 1
                                }
                            }

                            
                            for (const amount in amountOfItems) {
                                const keys = amount.split('#')

                                
                                bodies.push({
                                    scryfallUrl: `https://api.scryfall.com/cards/${keys[0]}/${keys[1]}`,
                                    cardId: keys[1],
                                    setId: keys[0],
                                    priceType: data?.priceType,
                                    condition: keys[2],
                                    quantity: parseInt(amountOfItems[amount]),
                                    priceBought: keys[4],
                                    dateBought: keys[5],
                                })
                            }
                        } else if (data?.['Folder Name']) {
                            const setId = data?.['Set Code']
                            const cardId = data?.['Card Number']
                            const quantity = isNaN(parseInt(data?.['Quantity'])) ? 1 : parseInt(data?.['Quantity'])
                            const priceBought = parseInt(data?.['Price Bought'] ? data?.['Price Bought']?.replace('.', '')?.replace(',', '') : 0).toLocaleString()
                            const dateBought = new Date(data?.['Date Bought']).toISOString()

                            let priceType = data?.['Printing']
                            if (priceType === 'Normal') {
                                priceType = 'usd'
                            } else if (priceType === 'Foil') {
                                priceType = 'usd_foil'
                            } else if (priceType === 'Etched Foil') {
                                priceType = 'usd_etched'
                            } else {
                                priceType = 'usd'
                            }

                            let condition = data?.['Condition']
                            if (condition === 'NearMint') {
                                condition = 'NEAR_MINT'
                            } else if (condition === 'Mint') {
                                condition = 'MINT'
                            } else {
                                condition = 'MODERATELY_PLAYED'
                            }

                            bodies.push({
                                scryfallUrl: `https://api.scryfall.com/cards/${setId}/${cardId}`,
                                cardId,
                                setId,
                                priceType,
                                condition,
                                quantity,
                                priceBought,
                                dateBought,
                            })
                        }

                        for (const body of bodies) {
                            try {
                                const addMtgCardResponse = post({
                                    apiName: "api",
                                    path: '/add-mtg-card',
                                    options: {
                                        headers: {
                                            Authorization: user.token ?? '',
                                        },
                                        body
                                    }
                                });
                                await addMtgCardResponse.response
                            } catch (error) {
                                setErroredFields((prev: any) => [ ...prev, JSON.stringify(body) ])
                            }
                            await sleep({ time: 350 })
                        }
                        setAmountOfObjectsCompleted((prev) => prev + 1)
                    }
                } catch (error) {
                    console.error(error)
                }

                setIsLoading(false)
            },
        });
    }

    return (
        <SubscriptionProtection
            resourceDisplayName="MTG Card Inventory"
            resourceKey="mtgInventoryEnabled"
        >
            <HeaderBackground className='text-2xl'>
                <NavLink to='/mtg-card-inventory'>Back</NavLink>
            </HeaderBackground>
            <div className={clsx('py-6 px-8')}>
                <div className={clsx('flex gap-6 justify-between px-4 border-b-2 pb-4 mb-4 border-zinc-400')}>
                    <h2 className={clsx('font-semibold text-xl')}>Upload MTG</h2>
                </div>
                <div className={clsx('flex flex-wrap justify-evenly gap-2 py-4 w-full overflow-scroll')}>
                    <input 
                        disabled={isLoading}
                        type="file" 
                        className={clsx('file-input file-input-bordered w-full max-w-xs')} 
                        onChange={(event: any) => {
                            if (event?.target?.files?.[0]) {
                                setCsvFile(event.target.files[0])
                            }
                        }}
                    />
                    <Select
                        className={clsx('h-fit')}
                        value={toDeleteInventory}
                        onChange={(value) => {
                            setToDeleteInventory(value)
                        }}
                        options={
                            [
                                {
                                    name: 'Keep',
                                    value: 'keep'
                                },
                                {
                                    name: 'Delete',
                                    value: 'delete'
                                },
                            ]
                        }
                    >
                        Delete Current Inventory Before Upload
                    </Select>
                    <Modal
                        id={`modal-delete-inventory`}
                        actionElements={
                            <div className={clsx('flex w-full justify-between gap-4')} >
                                <Button 
                                    className={clsx('flex-grow w-1/2 bg-zinc-400 border-zinc-400')}
                                    onClick={() => {
                                        (document?.getElementById(`modal-delete-inventory`) as any)?.close()
                                    }}
                                >
                                    Close
                                </Button>
                                <Button 
                                    className={clsx('flex-grow w-1/2 bg-red-500 border-red-500')}
                                    onClick={async () => {
                                        (document?.getElementById(`modal-delete-inventory`) as any)?.close()
                                        await uploadInventory()
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        }
                    >
                        <h3 className="font-bold text-2xl">Delete Inventory</h3>
                        <p className="py-4">Are you sure you want to delete <strong>All your inventory</strong>?</p>
                    </Modal>
                    <Button 
                        disable={isLoading}
                        className={clsx('flex-grow px-6 my-6')} 
                        onClick={async () => {
                            setIsLoading(true)
                            setAmountOfObjectsCompleted(0)
                            setObjectAmount(0)
                            if (toDeleteInventory === 'delete') {
                                (document?.getElementById(`modal-delete-inventory`) as any)?.showModal()
                                return
                            }
                            await uploadInventory()
                        }}
                    >
                        {isLoading ? <div className={clsx('w-8 h-8 mx-auto')}><Loading /></div> : 'Upload Cards' }
                    </Button>
                    <div className={clsx('flex flex-col gap-2')}>
                        <h4 className={clsx('font-semibold text-lg')}>{amountOfObjectsCompleted}/{objectAmount} Cards</h4>
                    </div>
                    {
                        erroredFields?.length > 0 &&
                            <div className={clsx('flex flex-col gap-2')}>
                                <h4 className={clsx('font-semibold text-lg')}>Errored on Cards:</h4>
                                <ul>
                                    {
                                        erroredFields?.map((card: any) => {
                                            return (
                                                <li className={clsx('text-red-500')}>{card}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                    }
                </div>
            </div>
        </SubscriptionProtection>
    );
};

export default UploadMtgCardsToInventory;
