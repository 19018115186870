
// Helpers
import { rarityScore } from "./index";

const orderByPrice = ({ direction, cards }: { direction: string, cards: any[] }) => {
    if (direction === 'asc') {
        return cards.sort(
            (a: any, b: any) => a.prices?.[(a?.prices?.length ?? 1) - 1] - b.prices?.[(b?.price?.length ?? 1) - 1]
        )
    } else {
        return cards.sort(
            (a: any, b: any) => b.prices?.[(b?.price?.length ?? 1) - 1] - a.prices?.[(a?.price?.length ?? 1) - 1]
        )
    }
}

const orderBySpent = ({ direction, cards }: { direction: string, cards: any[] }) => {
    if (direction === 'asc') {
        return cards.sort(
            (a: any, b: any) => (a.items?.reduce(
                (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
            )) - (b.items?.reduce(
                (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
            ))
        )
    } else {
        return cards.sort(
            (a: any, b: any) => (b.items?.reduce(
                (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
            )) - (a.items?.reduce(
                (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
            ))
        )
    }
}

const orderByTotalPrice = ({ direction, cards }: { direction: string, cards: any[] }) => {
    if (direction === 'asc') {
        return cards.sort(
            (a: any, b: any) => (a?.price * (a?.items?.length ?? 0)) - (b?.price * (b?.items?.length ?? 0))
        )
    } else {
        return cards.sort(
            (a: any, b: any) => (b?.price * (b?.items?.length ?? 0)) - (a?.price * (a?.items?.length ?? 0))
        )
    }
}

const orderByProfit = ({ direction, cards }: { direction: string, cards: any[] }) => {
    if (direction === 'asc') {
        return cards.sort(
            (a: any, b: any) => {
                const aProfit = ((a?.price * (a?.items?.length ?? 0)) - ((a?.items ?? []).reduce(
                    (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
                )))
                const bProfit = ((b?.price * (b?.items?.length ?? 0)) - ((b?.items ?? []).reduce(
                    (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
                )))
                return aProfit - bProfit
            }
        )
    } else {
        return cards.sort(
            (a: any, b: any) => {
                const aProfit = ((a?.price * (a?.items?.length ?? 0)) - ((a?.items ?? []).reduce(
                    (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
                )))
                const bProfit = ((b?.price * (b?.items?.length ?? 0)) - ((b?.items ?? []).reduce(
                    (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
                )))
                return bProfit - aProfit
            }
        )
    }
}

const orderByName = ({ direction, cards }: { direction: string, cards: any[] }) => {
    if (direction === 'asc') {
        return cards.sort(
            (a: any, b: any) => 
                (a.cardName.toLowerCase() > b.cardName.toLowerCase()) ? 1 : ((b.cardName.toLowerCase() > a.cardName.toLowerCase()) ? -1 : 0)
        )
    } else {
        return cards.sort(
            (a: any, b: any) => 
                (b.cardName.toLowerCase() > a.cardName.toLowerCase()) ? 1 : ((a.cardName.toLowerCase() > b.cardName.toLowerCase()) ? -1 : 0)
        )
    }
}

const orderByRarity = ({ direction, cards }: { direction: string, cards: any[] }) => {
    if (direction === 'asc') {
        return cards.sort(
            (a: any, b: any) => 
                ((rarityScore({ rarity: a.rarity}) ?? 0) > (rarityScore({ rarity: b.rarity}) ?? 0) ? 1 : (((rarityScore({ rarity: b.rarity}) ?? 0) > (rarityScore({ rarity: a.rarity}) ?? 0) ? -1 : 0))
            )
        )
    } else {
        return cards.sort(
            (a: any, b: any) => 
                ((rarityScore({ rarity: b.rarity}) ?? 0) > (rarityScore({ rarity: a.rarity}) ?? 0) ? 1 : (((rarityScore({ rarity: a.rarity}) ?? 0) > (rarityScore({ rarity: b.rarity}) ?? 0) ? -1 : 0))
            )
        )
    }
}

const orderByQuantity = ({ direction, cards }: { direction: string, cards: any[] }) => {
    if (direction === 'asc') {
        return cards.sort(
            (a: any, b: any) => 
                ((a?.items?.length ?? 0) > (b?.items?.length ?? 0) ? 1 : (((b?.items?.length ?? 0) > (a?.items?.length ?? 0) ? -1 : 0))
            )
        )
    } else {
        return cards.sort(
            (a: any, b: any) => 
                ((a?.items?.length ?? 0) < (b?.items?.length ?? 0) ? 1 : (((b?.items?.length ?? 0) < (a?.items?.length ?? 0) ? -1 : 0))
            )
        )
    }
}

export const orderByFunction = ({ orderBy, cards }: { orderBy: string, cards: any[] }) => {
    switch (orderBy) {
        case 'priceAsc':
            return orderByPrice({ direction: 'asc', cards })
        case 'priceDesc':
            return orderByPrice({ direction: 'dsc', cards })
        case 'spentAsc':
            return orderBySpent({ direction: 'asc', cards })
        case 'spentDesc':
            return orderBySpent({ direction: 'dsc', cards })
        case 'totalPriceAsc':
            return orderByTotalPrice({ direction: 'asc', cards })
        case 'totalPriceDesc':
            return orderByTotalPrice({ direction: 'dsc', cards })
        case 'profitAsc':
            return orderByProfit({ direction: 'asc', cards })
        case 'profitDesc':
            return orderByProfit({ direction: 'dsc', cards })
        case 'nameAsc':
            return orderByName({ direction: 'asc', cards })
        case 'nameDesc':
            return orderByName({ direction: 'dsc', cards })
        case 'rarityAsc':
            return orderByRarity({ direction: 'asc', cards })
        case 'rarityDesc':
            return orderByRarity({ direction: 'dsc', cards })
        case 'quantityAsc': 
            return orderByQuantity({ direction: 'asc', cards })
        case 'quantityDesc': 
            return orderByQuantity({ direction: 'dsc', cards })
        default:
            return cards
    }
}