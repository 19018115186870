/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
// Components
import SubscriptionProtection from "../../components/SubscriptionProtection";
// UI
import Header from "../../ui/Header";
import Button from "../../ui/Button";
import CollapseMenu from "../../ui/CollapseMenu";
import SearchBar from "../../ui/SearchBar";
import CardTable from "./components/CardTable"
import InventoryInfo from "./components/InventoryInfo";
// Helpers
import { orderByFunction } from "./helpers/ordering";
import { deleteCard as deleteCardFunction, getCards } from "./helpers/mtgCardInventoryBackend";
import { filterFunction } from "./helpers/filtering";
// Contexts
import { LoadingBlockerContext } from "../../contexts/loadingBlocker";
import { ClientContext } from "../../contexts/clients";
import { UserContext } from "../../contexts/user";

const MtgCardInventoryGenerator = () => {
    // States
    const [cards, setCards] = useState<any>([]);
    const [orderedCards, setOrderedCards] = useState<any>([]);
    const [filteredCards, setFilteredCards] = useState<any>([]);
    const [displayedCards, setDisplayedCards] = useState<any>([]);
    const [paginatedStart, setPaginatedStart] = useState<any>(0);
    const [pageNumber, setPageNumber] = useState<any>(1);
    const [paginatedEnd, setPaginatedEnd] = useState<any>(10);
    const [inventoryValue, setInventoryValue] = useState<any>(0);
    const [amountSpent, setAmountSpent] = useState<any>(0);
    const [amountOfCards, setAmountOfCards] = useState<any>(0);
    const [totalProfit, setTotalProfit] = useState<any>(0);
    const [totalOfUniqueCard, setTotalOfUniqueCard] = useState<any>(0);
    const [orderBy, setOrderBy] = useState<any>('priceDesc');
    const [filter, setFilter] = useState<any>('');
    // Contexts
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext)
    const [clients] = useContext<any>(ClientContext)
    const [user] = useContext<any>(UserContext)
    // Refs
    const grabbedCards = useRef<any>(false);

    const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    const deleteCard = async ({ card }: { card: any }) => {
        (document?.getElementById(`modal-${card?.setCardId}`) as any)?.close()
        loadingBlockerDispatch({
            type: 'ADD_BLOCKER',
            payload: 'DELETE_CARD_TO_INVENTORY'
        })

        await deleteCardFunction({ clients, userId: user?.userId, setCardId: card?.setCardId })
        setCards(await getCards({ userId: user.userId, clients }));

        loadingBlockerDispatch({
            type: 'REMOVE_BLOCKER',
            payload: 'DELETE_CARD_TO_INVENTORY'
        })
    }

    useEffect(() => {
        if (grabbedCards.current) return
        grabbedCards.current = true;

        (async () => {
            loadingBlockerDispatch({
                type: 'ADD_BLOCKER',
                payload: 'GET_CARD_TO_INVENTORY'
            })

            setCards(await getCards({ userId: user.userId, clients }));

            loadingBlockerDispatch({
                type: 'REMOVE_BLOCKER',
                payload: 'GET_CARD_TO_INVENTORY'
            })
        })()
    }, [])

    useEffect(() => {
        loadingBlockerDispatch({
            type: 'ADD_BLOCKER',
            payload: 'SET_FILTERED_CARD_INVENTORY'
        })

        setPageNumber(1)
        setPaginatedStart(0)
        setPaginatedEnd(10)
        setFilteredCards(filterFunction({ filter, cards }))

        loadingBlockerDispatch({
            type: 'REMOVE_BLOCKER',
            payload: 'SET_FILTERED_CARD_INVENTORY'
        })
    }, [filter, cards])

    useEffect(() => {
        loadingBlockerDispatch({
            type: 'ADD_BLOCKER',
            payload: 'SET_ORDERED_CARD_INVENTORY'
        })

        setOrderedCards([ ...orderByFunction({ orderBy, cards: filteredCards }) ])

        loadingBlockerDispatch({
            type: 'REMOVE_BLOCKER',
            payload: 'SET_ORDERED_CARD_INVENTORY'
        })
    }, [orderBy, filteredCards])

    useEffect(() => {
        let tempTotalValue = 0
        let tempTotalValueSpent = 0
        let totalNumberOfCards = 0
        let tempUniqueCards: { [ key: string ]: boolean } = {}

        for (const card of orderedCards) {
            tempTotalValue = tempTotalValue + ((card?.prices?.[(card?.price?.length ?? 1) - 1] ?? 0) * (card.items?.length ?? 0))
            tempTotalValueSpent = 
                tempTotalValueSpent + 
                card.items?.reduce(
                    (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
                )
            totalNumberOfCards = totalNumberOfCards + (card.items?.length ?? 0)
            tempUniqueCards[`${card.cardName}`] = true
        }

        setInventoryValue(currencyFormat.format(tempTotalValue/100))
        setAmountSpent(currencyFormat.format(tempTotalValueSpent/100))
        setAmountOfCards(totalNumberOfCards)
        setTotalProfit(currencyFormat.format((tempTotalValue - tempTotalValueSpent)/100))
        setTotalOfUniqueCard(Object.keys(tempUniqueCards).length)
        setPageNumber(1)
        setPaginatedStart(0)
        setPaginatedEnd(10)
    }, [orderedCards])

    useEffect(() => {
        const tempPaginatedCards = [ ...orderedCards ]
        setDisplayedCards(tempPaginatedCards.slice(paginatedStart, paginatedEnd))
    }, [orderedCards, paginatedStart, paginatedEnd])

    return (
        <SubscriptionProtection
            resourceDisplayName="MTG Card Inventory"
            resourceKey="mtgInventoryEnabled"
        >
            <Header />
            <div className={clsx('py-6 px-8')}>
                <div className={clsx('pb-4')}>
                    <div className={clsx('flex gap-6 justify-between px-4 border-b border-zinc-400 pb-4')}>
                        <h2 className={clsx('font-semibold text-xl h-fit my-auto dark:text-white')}>MTG Cards</h2>
                        <div className={clsx('flex flex-col gap-2')}>
                            <NavLink to='/mtg-card-inventory/upload'>
                                <Button className={clsx('flex-grow px-6')} onClick={() => {}}>Upload Inventory</Button>
                            </NavLink>
                            <NavLink to='/mtg-card-inventory/add'>
                                <Button className={clsx('flex-grow px-6')} onClick={() => {}}>Add Card</Button>
                            </NavLink>
                        </div>
                    </div>
                    <CollapseMenu
                        className={'border-b border-zinc-400'}
                        title='Inventory Details'
                    >
                        <InventoryInfo
                            inventoryValue={inventoryValue}
                            amountSpent={amountSpent}
                            amountOfCards={amountOfCards}
                            totalProfit={totalProfit}
                            totalOfUniqueCard={totalOfUniqueCard}
                        />
                    </CollapseMenu>
                    <SearchBar 
                        className={clsx('mt-4')} 
                        placeHolderExtra='EX: rarity::common, name::Ancient Copper Dragon, type::foil, ect'
                        onChange={(value) => {
                            setFilter(value)
                        }}
                    />
                </div>
                <div className={clsx('flex flex-wrap justify-evenly gap-2 py-4 w-full overflow-scroll')}>
                    <CardTable 
                        setOrderBy={setOrderBy}
                        orderBy={orderBy}
                        cards={displayedCards}
                        deleteCard={deleteCard}
                    />
                </div>
                <center className={clsx('pt-4')}>
                    <div className={clsx('join')}>
                        <button 
                            className="join-item btn"
                            disabled={pageNumber === 1}
                            onClick={() => {
                                if (pageNumber > 1) {
                                    setPaginatedStart((prev: number) => prev - 10)
                                    setPaginatedEnd((prev: number) => prev - 10)
                                    setPageNumber((prev: number) => prev - 1)
                                }
                            }}
                        >
                            «
                        </button>
                        <button 
                            className="join-item btn"
                        >
                            Page {pageNumber}/{Math.ceil(orderedCards.length/10) === 0 ? 1 : Math.ceil(orderedCards.length/10)}
                        </button>
                        <button 
                            className="join-item btn"
                            disabled={pageNumber === Math.ceil(orderedCards.length/10)}
                            onClick={() => {
                                if (pageNumber < Math.ceil(orderedCards.length/10)) {
                                    setPaginatedStart((prev: number) => prev + 10)
                                    setPaginatedEnd((prev: number) => prev + 10)
                                    setPageNumber((prev: number) => prev + 1 )
                                }
                            }}
                        >
                            »
                        </button>
                    </div>
                </center>
            </div>
        </SubscriptionProtection>
    );
};

export default MtgCardInventoryGenerator;
