// Packages
import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";

const OrderBy = ({
    className,
    children,
    orderBy,
    orderByState,
    setOrderBy,
}: {
    className?: string;
    children: ReactNode;
    orderBy: string;
    orderByState: string;
    setOrderBy: (orderBy: string) => void;
}) => {
    return (
        <button 
            className={clsx('flex gap-1', className)}
            onClick={() => {
                if (orderByState === `${orderBy}Asc`) {
                    setOrderBy(`${orderBy}Desc`)
                } else {
                    setOrderBy(`${orderBy}Asc`)
                }
            }}
        >
            {children}
            {
                orderByState === `${orderBy}Asc` &&
                    <IoIosArrowRoundUp />
            }
            {
                orderByState === `${orderBy}Desc` &&
                    <IoIosArrowRoundDown />
            }  
        </button>
    )
}

export default OrderBy