/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, ReactNode } from "react";
// Contexts
import { SubscriptionContext } from "../contexts/subscription";

const SubscriptionProtection = (
    { resourceKey, children, resourceDisplayName }:
    { resourceKey: string, children: ReactNode, resourceDisplayName: string }
) => {
  // Contexts
  const [subscription] = useContext<any>(SubscriptionContext);

  if (subscription?.[resourceKey]) {
    return <>{children}</>
  }

  return <p>You do not have an active subscription to use {resourceDisplayName}</p>
};

export default SubscriptionProtection;
