// Packages
import React from 'react'
// Pages
import TokenGenerator from "../pages/TokenGenerator/TokenGenerator"
import MtgCardInventory from "../pages/MtgCardInventory/MtgCardInventory"
import AddMtgCardToInventory from "../pages/MtgCardInventory/AddMtgCardToInventory"
import EditMtgCardToInventory from '../pages/MtgCardInventory/EditMtgCardToInventory';
import UploadMtgCardsToInventory from '../pages/MtgCardInventory/UploadMtgCardsToInventory';

export interface Product {
    pageTitle: string;
    path: string;
    subPaths: { path: string; component: React.ReactNode; }[],
    headerName: string;
    homePage: {
        title: string;
        description: string;
    },
    component: React.ReactNode;
}

export const products: { [key: string]: Product } = {
    tokenGenerator: {
        pageTitle: 'Token Generator',
        path: '/token-generator',
        subPaths: [],
        headerName: 'Token Generator',
        homePage: {
            title: 'Token Generator',
            description: 'Use the power of OpenAI\'s DALLE3 or StableDiffusion to make token stamps to be used in any table top game!',
        },
        component: (<TokenGenerator />),
    },
    mtgInventory: {
        pageTitle: 'MTG Card Inventory',
        path: '/mtg-card-inventory',
        subPaths: [
            {
                path: '/mtg-card-inventory/add',
                component: (<AddMtgCardToInventory />),
            },
            {
                path: '/mtg-card-inventory/upload',
                component: (<UploadMtgCardsToInventory />),
            },
            {
                path: '/mtg-card-inventory/edit/:setId/:cardId/:finish',
                component: (<EditMtgCardToInventory />),
            },
        ],
        headerName: 'MTG Card Inventory',
        homePage: {
            title: 'MTG Card Inventory',
            description: 'An inventory of your MTG cards.',
        },
        component: (<MtgCardInventory />),
    }
}

export const subscriptionKeyToProductKey = ({ subscriptionKey }: { subscriptionKey: string }) => {
    return subscriptionKey.replace('Enabled', '')
}