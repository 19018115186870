// Packages
import React from 'react'

function Loading({ color }: { color?: string }) {
    if (color === 'primary') {
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <circle fill="#342747" stroke="#342747" strokeWidth="15" r="15" cx="40" cy="65">
                <animate 
                    attributeName="cy" 
                    calcMode="spline" 
                    dur="2" 
                    values="65;135;65;" 
                    keySplines=".5 0 .5 1;.5 0 .5 1" 
                    repeatCount="indefinite" 
                    begin="-.4"
                >
                </animate>
            </circle>
            <circle fill="#342747" stroke="#342747" strokeWidth="15" r="15" cx="100" cy="65">
                <animate 
                    attributeName="cy" 
                    calcMode="spline" 
                    dur="2" 
                    values="65;135;65;" 
                    keySplines=".5 0 .5 1;.5 0 .5 1" 
                    repeatCount="indefinite" 
                    begin="-.2"
                >
                </animate>
            </circle>
            <circle fill="#342747" stroke="#342747" strokeWidth="15" r="15" cx="160" cy="65">
                <animate 
                    attributeName="cy" 
                    calcMode="spline" 
                    dur="2" 
                    values="65;135;65;" 
                    keySplines=".5 0 .5 1;.5 0 .5 1" 
                    repeatCount="indefinite" 
                    begin="0"
                >
                </animate>
            </circle>
        </svg>)
    }   

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <circle fill="#FFF" stroke="#FFF" strokeWidth="15" r="15" cx="40" cy="65">
                <animate 
                    attributeName="cy" 
                    calcMode="spline" 
                    dur="2" 
                    values="65;135;65;" 
                    keySplines=".5 0 .5 1;.5 0 .5 1" 
                    repeatCount="indefinite" 
                    begin="-.4"
                >
                </animate>
            </circle>
            <circle fill="#FFF" stroke="#FFF" strokeWidth="15" r="15" cx="100" cy="65">
                <animate 
                    attributeName="cy" 
                    calcMode="spline" 
                    dur="2" 
                    values="65;135;65;" 
                    keySplines=".5 0 .5 1;.5 0 .5 1" 
                    repeatCount="indefinite" 
                    begin="-.2"
                >
                </animate>
            </circle>
            <circle fill="#FFF" stroke="#FFF" strokeWidth="15" r="15" cx="160" cy="65">
                <animate 
                    attributeName="cy" 
                    calcMode="spline" 
                    dur="2" 
                    values="65;135;65;" 
                    keySplines=".5 0 .5 1;.5 0 .5 1" 
                    repeatCount="indefinite" 
                    begin="0"
                >
                </animate>
            </circle>
        </svg>
    )
}

export default Loading
