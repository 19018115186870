/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React from "react";
// Components
import SubscriptionProtection from "../../components/SubscriptionProtection";
// UI
import Header from "../../ui/Header";
import TableTopTokenGenerator from "./components/TableTopTokenGenerator";

const TokenGenerator = () => {
    return (
        <SubscriptionProtection
            resourceDisplayName="Token Generator"
            resourceKey="tokenGeneratorEnabled"
        >
            <Header />
            <TableTopTokenGenerator />
        </SubscriptionProtection>
    );
};

export default TokenGenerator;
