import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
      userPoolClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID ?? '',
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID ?? '',
      signUpVerificationMethod: "code",
    },
  },
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_API_ENDPOINT ?? '',
      region: process.env.REACT_APP_REGION ?? '',
      defaultAuthMode: 'userPool',
    },
    REST: {
      api: {
        endpoint: process.env.REACT_APP_REST_API_URL ?? '',
        region: process.env.REACT_APP_REGION ?? '',
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
