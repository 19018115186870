// Packages
import clsx from 'clsx'
import React, { useState, ReactNode } from 'react'

const TextArea = ({
    placeholder,
    value: initialValue,
    onChange,
    className,
    inputClassName,
    labelTextClassName,
    children,
    disable
}: {
    placeholder: string,
    value: string,
    onChange: (value: string) => void
    className?: string,
    inputClassName?: string,
    labelTextClassName?: string,
    children: ReactNode,
    disable?: boolean
}) => {
    // States
    const [value, setValue] = useState(initialValue)

    return (
        <label className={clsx('text-xs', disable ? 'cursor-not-allowed' : '', className)}>
            <p
                className={clsx('mx-2 mb-1', disable ? 'cursor-not-allowed' : '', labelTextClassName ?? '')}
            >
                {children}
            </p>
            <textarea 
                disabled={disable}
                value={value}
                className={clsx('text-base border-2 rounded-lg border-zinc-400 px-2 py-1 w-full', disable ? 'cursor-not-allowed' : '', inputClassName ?? '')}
                placeholder={placeholder}
                onChange={(event) => {
                    if (!disable) {
                        setValue(event.target.value)
                        onChange(event.target.value)
                    }
                }}
            />
        </label>
    )
}

export default TextArea