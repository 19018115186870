/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { createContext, useReducer, useMemo, useEffect, useRef, useContext } from 'react'
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth'
// Contexts
import UserReducer from './reducers'
import initialState from './initialState'
import { LoadingBlockerContext } from '../loadingBlocker'

export const UserContext = createContext({})

function Store({ children }: { children: React.ReactNode }) {
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext);
    const [state, dispatch] = useReducer(
        (reducerState: any, action: { type: String; payload?: any }) =>
            UserReducer(reducerState, action),
        initialState
    )
    const populateUser = useRef(false)

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    useEffect(() => {
        if (populateUser.current) return
        (async () => {
            populateUser.current = true
            loadingBlockerDispatch({
                type: 'ADD_PAGE_BLOCKER',
                payload: 'GET_USER'
            })
            dispatch({ type: 'SET_LOADING', payload: true })
            let user
            try {
                user = await getCurrentUser()
            } catch (error) {
                // Nothing...
            }

            let token = undefined
            let authSession = undefined
            try {
                authSession = await fetchAuthSession();
                token = authSession.tokens?.idToken?.toString();
            } catch (error) {
                // Nothing...
            }

            if (user) {
                dispatch({ type: 'SET_USER', payload: { ...user, authSession, token }})
                loadingBlockerDispatch({
                    type: 'REMOVE_PAGE_BLOCKER',
                    payload: 'GET_USER'
                })
            } else {
                dispatch({ type: 'SET_LOADING', payload: false })
                loadingBlockerDispatch({
                    type: 'REMOVE_PAGE_BLOCKER',
                    payload: 'GET_USER'
                })
            }
        })()
    }, [])

    return <UserContext.Provider value={stateVariables}>{children}</UserContext.Provider>
}

export default Store
