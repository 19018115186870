// Packages
import React from 'react'
import clsx from 'clsx'
// UI
import Loading from './Loading'

function LoadingBlocker() {
    return (
        <div className={clsx('bg-primary flex flex-col justify-center w-screen h-screen fixed z-[100000] top-0 left-0')}>
            <div className={clsx('mx-auto w-40 h-auto lg:w-60')}>
                <Loading />
            </div>
        </div>
    )
}

export default LoadingBlocker
