/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react'
// Queries
import { getSubscription } from "../../graphql/queries";
// Contexts
import ClientReducer from './reducers'
import initialState from './initialState'
import { ClientContext } from '../clients'
import { UserContext } from '../user'
import { LoadingBlockerContext } from '../loadingBlocker'

export const SubscriptionContext = createContext({})

function Store({ children }: { children: React.ReactNode }) {
    // Context
    const [clients] = useContext<any>(ClientContext);
    const [user] = useContext<any>(UserContext);
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext);
    const [state, dispatch] = useReducer(
        (reducerState: any, action: { type: String; payload?: any }) =>
            ClientReducer(reducerState, action),
        initialState
    )

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    useEffect(() => {
        if (clients.graphql && user.userId) {
            (async () => {
                loadingBlockerDispatch({
                    type: 'ADD_PAGE_BLOCKER',
                    payload: 'GET_SUBSCRIPTION'
                })
                try {
                    const result = await clients.graphql.graphql({
                        query: getSubscription,
                        variables: {
                            userId: user.userId
                        }
                    });
    
                    const { __typename, userId, subscriptionId, ...subscription } = result.data.getSubscription
                    dispatch({
                        type: 'SET_SUBSCRIPTION',
                        payload: subscription
                    })
                    
                    loadingBlockerDispatch({
                        type: 'REMOVE_PAGE_BLOCKER',
                        payload: 'GET_SUBSCRIPTION'
                    })
                } catch (error) {
                    loadingBlockerDispatch({
                        type: 'REMOVE_PAGE_BLOCKER',
                        payload: 'GET_SUBSCRIPTION'
                    })
                    console.error(error)
                }
            })()
        }
    }, [clients.graphql, user.userId])

    return <SubscriptionContext.Provider value={stateVariables}>{children}</SubscriptionContext.Provider>
}

export default Store
