// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const TableBodyRow = ({ 
    className,
    children,
}: { 
    className?: string;
    children: ReactNode;
}) => {
    return (
        <tbody className={clsx(className)}>
            {children}
        </tbody> 
    )
}

export default TableBodyRow