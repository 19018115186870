// Packages
import React, { useState, useContext } from "react";
import { signIn, fetchAuthSession } from "aws-amplify/auth";
import { NavLink, useNavigate } from "react-router-dom";
import { confirmSignIn } from "aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import clsx from "clsx";
// UI
import TextField from "../ui/TextField";
import Button from "../ui/Button";
import ErrorText from "../ui/ErrorText";
// Contexts
import { UserContext } from "../contexts/user";
import { LoadingBlockerContext } from "../contexts/loadingBlocker";

const Login = () => {
    // React Router
    const navigate = useNavigate();
    // States
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    // Contexts
    const [, userDispatch] = useContext<any>(UserContext)
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext)

    return (
        <div className={clsx('flex flex-col gap-4 mx-auto px-16 py-6 md:px-6 md:max-w-[400px]')}>
            <h1 
                className={clsx('text-3xl font-bold text-center')}
            >
                Login
            </h1>
            <TextField
                type="email"
                placeholder="Email"
                value={email}
                onChange={(value) => setEmail(value)}
            >
                Email
            </TextField>
            <TextField
                type="password"
                placeholder="Password"
                value={password}
                onChange={(value) => setPassword(value)}
            >
                Password
            </TextField>
            <NavLink className={clsx('text-primary text-right')} to='/signup'>Sign Up</NavLink>
            {
                error && 
                    <ErrorText className={clsx('mt-1')}>{error}</ErrorText>
            }
            <Button
                className={clsx('my-4')}
                onClick={async () => {
                    if (!email || !password) {
                        setError('Email and password are require')
                        return;
                    }
                
                    loadingBlockerDispatch({
                        type: 'ADD_BLOCKER',
                        payload: 'LOGIN'
                    })
                    try {
                        const { isSignedIn, nextStep } = await signIn({
                            username: email,
                            password,
                        });
                
                        if (isSignedIn) {
                            const user = await getCurrentUser()
                            const authSession = await fetchAuthSession();
                            const token = authSession.tokens?.idToken?.toString();
                            userDispatch({ type: 'SET_USER', payload: { ...user, authSession, token }});
                            navigate('/');
                        }
                    
                        if (nextStep) {
                            await confirmSignIn({
                                challengeResponse: password,
                            });
                            const user = await getCurrentUser()
                            const authSession = await fetchAuthSession();
                            const token = authSession.tokens?.idToken?.toString();
                            userDispatch({ type: 'SET_USER', payload: { ...user, authSession, token }});
                            loadingBlockerDispatch({
                                type: 'REMOVE_BLOCKER',
                                payload: 'LOGIN'
                            })
                            navigate('/');
                        }
                    } catch (error: any) {
                        console.error(error);
                        setError(error.message)
                        loadingBlockerDispatch({
                            type: 'REMOVE_BLOCKER',
                            payload: 'LOGIN'
                        })
                    }
                }}
            >
                Sign In
            </Button>
        </div>
    );
};

export default Login;
