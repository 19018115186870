// Packages
import React, { useContext } from 'react'
// UI
import LoadingBlocker from '../ui/LoadingBlocker'
// Contexts
import { LoadingBlockerContext } from '../contexts/loadingBlocker'

function DisplayPageBlocker({ children }: { children: React.ReactNode }) {
    const [loadingBlocker]: any = useContext(LoadingBlockerContext)

    if (Object.keys(loadingBlocker.pageBlockers).some(
        (blocker) => loadingBlocker.pageBlockers[blocker] === true
    )) {
        return <LoadingBlocker />
    }

    return <>{children}</>
}

export default DisplayPageBlocker
