// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'
// UI
import TableHead from './TableHead'
import TableHeadElement from './TableHeadElement'
import TableBody from './TableBody'
import TableBodyRow from './TableBodyRow'

const Table = ({ 
    className,
    rows,
    headers,
    id,
}: { 
    className?: string;
    rows: ReactNode[][];
    headers: ReactNode[];
    id: string;
}) => {
    return (
        <table className={clsx('table overflow-x-scroll', className)}>
            <TableHead id={id}>
                {headers.map((header, index) => (
                    <TableHeadElement 
                        key={`table-head-element-${id}-${index}`}
                    >
                        {header}
                    </TableHeadElement>
                ))}
            </TableHead>
            <TableBody>
                {
                    rows.map((row: ReactNode[], index: number) => (
                        <TableBodyRow 
                            id={id}
                            key={`table-body-${id}-${index}`}
                            columns={row}
                        />
                    ))
                }
            </TableBody>
        </table>  
    )
}

export default Table