// Helpers
import initialState from './initialState'

const UserReducer = (state: any, action: { type: String; payload?: any }) => {
    switch (action.type) {
        case 'SET':
            return action.payload
        case 'SET_USER':
            return { isLoading: false, isSignedIn: true, ...action.payload }
        case 'SET_USER_NAME':
            return { ...state, userName: action.payload }
        case 'RESET_USER':
            return { isLoading: false, isSignedIn: false }
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload }
        case 'SET_DEFAULT':
            return initialState
        default:
            return state
    }
}

export default UserReducer
