// Packages
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { GiHamburgerMenu } from "react-icons/gi";
// UI
import Avatar from './Avatar';
import DropDown from './DropDown';
import HeaderBackground from './HeaderBackground';
// Contexts
import { ProductsContext } from "../contexts/products";
import { UserContext } from "../contexts/user";

const Header = () => {
    // Contexts
    const [products] = useContext<any>(ProductsContext)
    const [user] = useContext<any>(UserContext)

    return (
        <>
            <HeaderBackground className={clsx('text-2xl md:hidden')}>
                <div className={clsx('flex gap-4 h-fit my-auto')}>
                    <div className={clsx('drawer h-fit')}>
                        <input 
                            id='mobileProductMenu' 
                            type='checkbox' 
                            className={clsx('drawer-toggle')} 
                        />
                        <div className={clsx('drawer-content h-fit')}>
                            <label 
                                htmlFor="mobileProductMenu" 
                                className={clsx('drawer-button')}
                            >
                                <GiHamburgerMenu className={clsx('w-8 h-auto')} />
                            </label>
                        </div>
                        <div className={clsx('drawer-side z-[2]')}>
                            <label 
                                htmlFor="mobileProductMenu" 
                                aria-label="close sidebar" 
                                className={clsx('drawer-overlay')}
                            />
                            <ul 
                                className={clsx('menu bg-base-200 text-base-content min-h-full w-80 p-4')}
                            >
                                {
                                    Object.keys(products).map((product: any, i: number) => {
                                        return (
                                            <li key={`${products[product].path}-${i}`}>
                                                <NavLink to={products[product].path}>{products[product].headerName}</NavLink>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <NavLink className={clsx('h-fit')} to='/'>Home</NavLink>
                </div>
                <DropDown 
                    keyIdentifier='header'
                    button={
                        <Avatar 
                            internalClassName={clsx('w-9')} 
                            userName={user?.signInDetails?.loginId ?? ''} 
                            textClassName={clsx('text-base')}
                        />
                    } 
                    options={[
                        <NavLink to='/logout'>Logout</NavLink>,
                    ]}
                />
            </HeaderBackground>
            <HeaderBackground className={clsx('hidden md:flex text-2xl')}>
                <div className={clsx('flex gap-8 h-fit my-auto')}>
                    <NavLink className={clsx('h-fit py-1')} to='/'>Home</NavLink>
                    <DropDown 
                        keyIdentifier='header'
                        button={<div className={clsx('border-white border-2 px-2 py-1 rounded-lg hover:bg-white hover:text-primary active:bg-white active:text-primary')}>Products</div>} 
                        options={[
                            ...Object.keys(products).map((product: any, i: number) => {
                                return (
                                    <NavLink key={`${products[product].path}-${i}`} to={products[product].path}>{products[product].headerName}</NavLink>
                                )
                            })
                        ]}
                    />
                </div>
                <DropDown 
                    keyIdentifier='header'
                    button={
                        <Avatar
                            internalClassName={clsx('w-14')} 
                            userName={user?.signInDetails?.loginId ?? ''} 
                            textClassName={clsx('text-2xl')}
                        />
                    } 
                    options={[
                        <NavLink to='/logout'>Logout</NavLink>,
                    ]}
                />
            </HeaderBackground>
        </>
    )
}

export default Header