/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useState, useContext } from 'react'
import clsx from 'clsx'
import { post } from 'aws-amplify/api'
import { base64LoadingPng } from '../../../helpers/base64Images'
// UI
import TextArea from '../../../ui/TextArea'
import Button from '../../../ui/Button'
// Contexts
import { UserContext } from '../../../contexts/user'

const DalleTableTopTokenGenerator = ({ 
    renderNewImage, saveImage, isLoading, setIsLoading
}: { 
    renderNewImage: Function
    saveImage: Function
    isLoading: boolean
    setIsLoading: Function
}) => {
    // States
    const [prompt, setPrompt] = useState('')
    const [disableDownload, setDisableDownload] = useState(true)
    // Contexts
    const [user] = useContext<any>(UserContext)

    return (
        <>
            <TextArea 
                disable={isLoading}
                value={prompt}
                placeholder='Prompt' 
                onChange={(value) => {
                    setPrompt(value)
                }}
            >
                Prompt
            </TextArea>
            <div className={clsx('flex gap-4 justify-end my-2 md:my-6')}>
                <Button 
                    disable={isLoading}
                    className={clsx('w-full')}
                    onClick={async () => {
                        setIsLoading(true)
                        setDisableDownload(true)
                        renderNewImage(base64LoadingPng(), true)
                        const response = post({
                            apiName: "api",
                            path: '/generate-token',
                            options: {
                                headers: {
                                    Authorization: user.token ?? '',
                                },
                                body: {
                                    model: 'dalle',
                                    promptType: 'raw',
                                    prompt
                                }
                            }
                        });
                        const { body } = await response.response;
                        const data: any = await body.json();
                        renderNewImage(data?.image)
                        setIsLoading(false)
                        setDisableDownload(false)
                    }}
                >
                    Generate
                </Button>
                <Button
                    disable={isLoading || disableDownload}
                    className={clsx('w-full')}
                    onClick={() => {
                        saveImage()
                    }}
                >
                    Download
                </Button>
            </div>
        </>
    )
}

export default DalleTableTopTokenGenerator