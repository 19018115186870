// Packages
import React, { useContext } from 'react'
// UI
import LoadingBlocker from '../ui/LoadingBlocker'
// Contexts
import { LoadingBlockerContext } from '../contexts/loadingBlocker'

function DisplayLoadingBlocker() {
    const [loadingBlockers]: any = useContext(LoadingBlockerContext)

    if (Object.keys(loadingBlockers.blockers).some((blocker) => loadingBlockers.blockers[blocker] === true)) {
        return <LoadingBlocker />
    }
    return <></>
}

export default DisplayLoadingBlocker
