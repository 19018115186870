// Packages
import React, { ReactNode } from 'react'
import clsx from 'clsx'
// UI

function Modal({ id, children, actionElements, }: { id: string; children: ReactNode; actionElements?: ReactNode; }) {
    return (
        <dialog id={id} className={clsx('modal')}>
            <div className={clsx('modal-box')}>
                {children}
                <div className={clsx('modal-action')}>
                    {actionElements}
                </div>
            </div>
            <form method="dialog" className={clsx('modal-backdrop')}>
                <button className={clsx('cursor-default')}>close</button>
            </form>
        </dialog>
    )
}

export default Modal
