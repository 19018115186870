// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const CollapseMenu = ({ 
    className,
    children,
    title,
}: { 
    className?: string;
    children: ReactNode;
    title: string;
}) => {
    return (
        <details className={clsx('collapse bg-white text-black dark:text-white dark:bg-base-100 collapse-arrow border-b w-full rounded-none h-fit overflow-visible', className)}>
            <summary className={clsx('collapse-title text-xl font-medium w-full h-fit')}>{title}</summary>
            <div className={clsx('collapse-content border-t pt-2 h-fit')}>
                {children}
            </div>
        </details>  
    )
}

export default CollapseMenu