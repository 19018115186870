export const addMtgCardValidator = ({
    cardType,
    quantity,
    card,
    condition,
    boughtDate,
    boughtAt,
}: {
    cardType: string,
    quantity: string,
    card: any,
    condition: string,
    boughtDate: string,
    boughtAt: string,
}) => {
    if (cardType === '') {
        return {
            isValid: false,
            error: 'Please select a card type'
        }
    } else if (quantity === '0') {
        return {
            isValid: false,
            error: 'Please set a quantity'
        }
    } else if (!card) {
        return {
            isValid: false,
            error: 'Please search for a card before adding to inventory'
        }
    } else if (!condition) {
        return {
            isValid: false,
            error: 'Please select a condition'
        }
    } else if (/(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/.test(boughtDate)) {
        return {
            isValid: false,
            error: 'Please enter a valid date in MM-DD-YYYY format for bought at'
        }
    } else if (isNaN(parseFloat(boughtAt))) {
        return {
            isValid: false,
            error: 'Please enter a number for price bought at'
        }
    } else if (!boughtDate) {
        return {
            isValid: false,
            error: 'Please enter a date bought'
        }
    } else {
        return {
            isValid: true,
            error: ''
        }
    }
}