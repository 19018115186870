export const cardConditionSelector = [
    {
        name: 'None',
        value: ''
    },
    {
        name: 'Mint',
        value: 'MINT'
    },
    {
        name: 'Near Mint',
        value: 'NEAR_MINT'
    },
    {
        name: 'Lightly Played',
        value: 'LIGHTLY_PLAYED'
    },
    {
        name: 'Moderately Played',
        value: 'MODERATELY_PLAYED'
    },
    {
        name: 'Heavily Played',
        value: 'HEAVILY_PLAYED'
    },
    {
        name: 'Damaged',
        value: 'DAMAGED'
    },
]