// Helpers
import initialState from './initialState'

const UserReducer = (state: any, action: { type: String; payload?: any }) => {
    switch (action.type) {
        case 'SET':
            return action.payload
        case 'SET_DEFAULT':
            return initialState
        default:
            return state
    }
}

export default UserReducer
