// Packages
import clsx from 'clsx'

const Avatar = ({ 
    userName, 
    className, 
    internalClassName,
    textClassName
}: { 
    userName: string, 
    internalClassName?: string,
    className?: string,
    textClassName?: string
}) => {
    return (
        <div className={clsx('avatar placeholder', className)}>
            <div className={clsx('bg-white text-secondary rounded-full', internalClassName)}>
                <span className={clsx(textClassName)}>{userName.substring(0, 1).toLocaleUpperCase()}</span>
            </div>
        </div>     
    )
}

export default Avatar