// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const CarouselWithButtons = ({ 
    items,
    id,
}: { 
    items: ReactNode[];
    id: string;
}) => {
    return (
        <>
            <div className={clsx('carousel w-full')}>
                {
                    items.map((item: ReactNode, i: number) => {
                        return (
                            <div 
                                id={`item${i + 1}`}
                                key={`item-carousel-${i}`} 
                                className={clsx('carousel-item w-full')}
                            >
                                {item}
                            </div>
                        )
                    })
                }
            </div>
            <div className={clsx('flex w-full justify-center gap-2 py-2')}>
                {
                    items.map((_: ReactNode, i: number) => {
                        return (
                            <a 
                                key={`item-carousel-button-${i}`}
                                href={`#item${i + 1}`}
                                className={clsx('btn btn-xs')}
                            >
                                {i + 1}
                            </a>
                        )
                    })
                }
            </div>
        </>
    )
}

export default CarouselWithButtons