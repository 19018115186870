// Packages
import React from 'react'
import clsx from 'clsx'
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// UI
import Table from "../../../../ui/Table";
import CarouselWithButtons from '../../../../ui/CarouselWithButtons';
import Chip from "../../../../ui/Chip";
import Modal from "../../../../ui/Modal";
import Button from "../../../../ui/Button";
// Helpers
import { cardTypeToDisplayName } from "../../helpers";
import { capitalizeFirstLetter } from "../../../../helpers";
import Header from './Header'

const CardTable = ({ 
    className,
    setOrderBy,
    orderBy,
    cards,
    deleteCard,
}: { 
    className?: string;
    setOrderBy: (orderBy: string) => void;
    orderBy: string;
    cards: any[];
    deleteCard: (card: any) => void
}) => {
    // Navigation
    const navigate = useNavigate();

    const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    return (
        <Table 
            id='mtg-card-inventory-table'
            className={clsx(className)}
            headers={Header({
                setOrderBy,
                orderBy,
            })}
            rows={[
                ...cards.map((card: any) => {
                    let imageElement = <p>
                        No images found
                    </p>
                    
                    if (card?.imageUrl?.length === 1) {
                        imageElement = card?.imageUrl?.map((imageUrlString: string, i: number) => (
                            <img 
                                key={`card-face-image-image-${card?.name}-${card?.priceType}-${i}`}
                                className={clsx('mx-auto')}
                                src={imageUrlString} 
                                alt={`A scanned version of the card ${card?.name}`}
                            />
                        ))
                    } else if (card?.imageUrl?.length > 1) {
                        imageElement = <CarouselWithButtons 
                            id='mtgCardInventoryImage'
                            items={
                                card?.imageUrl?.map((imageUrlString: string, i: number) => (
                                    <img 
                                        key={`card-face-image-image-${card?.name}-${card?.priceType}-${i}`}
                                        className={clsx('mx-auto')}
                                        src={imageUrlString} 
                                        alt={`A scanned version of the card ${card?.name}`}
                                    />
                                ))
                            }
                        />
                    }

                    const totalProfit = (((card?.prices?.[(card?.prices?.length ?? 1) - 1] ?? 0) * (card?.items?.length ?? 0)) - ((card?.items ?? []).reduce(
                        (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
                    )))

                    let rarityChipColor = `text-xs border-white border-2 text-white p-0.5 `
                    if (card?.rarity === 'mythic') {
                        rarityChipColor += 'bg-gradient-to-tr from-[rgb(180,50,25)] via-[rgb(245,145,5)] to-[rgb(180,50,25)]'
                    } else if (card?.rarity === 'rare') {
                        rarityChipColor += 'bg-gradient-to-tr from-[rgb(118,98,55)] via-[rgb(230,205,140)] to-[rgb(118,98,55)]'
                    } else if (card?.rarity === 'uncommon') {
                        rarityChipColor += 'bg-gradient-to-tr from-[rgb(70,100,110)] via-[rgb(185,220,235)] to-[rgb(70,100,110)]'
                    } else if (card?.rarity === 'common') {
                        rarityChipColor += 'bg-[rgb(33,33,33)]'
                    } else if (card?.rarity === 'shifted') {
                        rarityChipColor += 'bg-gradient-to-br from-[rgb(100,40,120)] via-[rgb(195,155,200)] to-[rgb(100,40,120)]'
                    } else {
                        rarityChipColor += 'bg-[rgb(33,33,33)]'
                    }

                    let finishChipColor = `text-xs p-0.5 border-2 `
                    if (card?.priceType === 'usd') {
                        finishChipColor += ''
                    } else {
                        finishChipColor += 'border-black border-2 text-black bg-gradient-to-tr from-[#B38728] via-[#FBF5B7] to-[#B38728]'
                    }

                    return [
                        <div className={clsx('w-24 md:w-48 h-auto')}>
                            {imageElement}
                        </div>,
                        card?.cardName,
                        <Chip className={clsx(finishChipColor)}>{cardTypeToDisplayName({ cardType: card?.priceType })}</Chip>,
                        <Chip className={clsx(rarityChipColor)}>{capitalizeFirstLetter({ value: card?.rarity })}</Chip>,
                        currencyFormat.format((card?.prices?.[(card?.prices?.length ?? 1) - 1] ?? 0)/100),
                        currencyFormat.format(card.items?.reduce(
                            (tempSum: number, value: any) => tempSum + (value?.priceBought ?? 0), 0
                        )/100),
                        currencyFormat.format((card?.prices?.[(card?.prices?.length ?? 1) - 1] ?? 0) * (card?.items?.length ?? 0)/100),
                        <div className={clsx(totalProfit > 0 ? 'text-green-500' : 'text-red-500')}>{currencyFormat.format(totalProfit/100)}</div>,
                        card?.items?.length ?? 0,
                        <button
                            type='button'
                            className={clsx('text-xl md:text-2xl')}
                            onClick={() => {
                                navigate(`/mtg-card-inventory/edit/${card?.setId}/${card?.cardId}/${card?.priceType}`)
                            }}
                        >
                            <FaRegEdit />
                        </button>,
                        <>
                            <button
                                type='button'
                                className={clsx('text-xl md:text-2xl text-red-500')}
                                onClick={async () => {
                                    (document?.getElementById(`modal-${card?.setCardId}`) as any)?.showModal()
                                }}
                            >
                                <FaTrash />
                            </button>
                            <Modal
                                id={`modal-${card?.setCardId}`}
                                actionElements={
                                    <div className={clsx('flex w-full justify-between gap-4')} >
                                        <Button 
                                            className={clsx('flex-grow w-1/2 bg-zinc-400 border-zinc-400')}
                                            onClick={() => {
                                                (document?.getElementById(`modal-${card?.setCardId}`) as any)?.close()
                                            }}
                                        >
                                            Close
                                        </Button>
                                        <Button 
                                            className={clsx('flex-grow w-1/2 bg-red-500 border-red-500')}
                                            onClick={() => deleteCard({ card })}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                }
                            >
                                <h3 className="font-bold text-2xl">Delete {card?.cardName}</h3>
                                <p className="py-4">Are you sure you want to delete this card?</p>
                            </Modal>
                        </>,
                    ]
                })
            ]}
        />   
    )
}

export default CardTable