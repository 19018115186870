// Helpers
import { displayNameToCardType } from "./index";

const defaultEvalFunction = (
    card: any, internalFilteredField: any, internalFilteredValue: any
) => card?.[internalFilteredField] === internalFilteredValue

const filters = ({ splitFilter, notOffset }: { splitFilter: any[], notOffset: number }) => {
    let filteredField = splitFilter?.[0 + notOffset]
    let filteredValue: any = splitFilter?.[1 + notOffset]?.toLowerCase()

    switch (filteredField?.toLowerCase()) {
        case 'finish':
            return {
                filteredField: 'priceType',
                filteredValue: displayNameToCardType({ displayName: splitFilter?.[1] }),
                evalFunction: defaultEvalFunction,
            }
        case 'name':
            filteredField = 'cardName'
            return {
                filteredField,
                filteredValue,
                evalFunction: (
                    card: any, internalFilteredField: any, internalFilteredValue: any
                ) => card?.[internalFilteredField]?.toLowerCase()?.includes(internalFilteredValue),
            }
        case 'price': {
            const filteredSymbol = splitFilter?.[1 + notOffset]?.toLowerCase()
            let parsedValue = parseInt(splitFilter?.[2 + notOffset] ?? '0')

            if (filteredSymbol === 'greaterthan') {
                return {
                    filteredField: 'prices',
                    filteredValue: (isNaN(parsedValue) ? 0 : parsedValue) * 100,
                    evalFunction: (
                        card: any, internalFilteredField: any, internalFilteredValue: any
                    ) => card?.[internalFilteredField]?.[(card?.price?.length ?? 1) - 1] >= internalFilteredValue,
                }
            } else if (filteredSymbol === 'lessthan') {
                return {
                    filteredField: 'prices',
                    filteredValue: (isNaN(parsedValue) ? 0 : parsedValue) * 100,
                    evalFunction: (
                        card: any, internalFilteredField: any, internalFilteredValue: any
                    ) => card?.[internalFilteredField]?.[(card?.price?.length ?? 1) - 1] <= internalFilteredValue,
                }
            } else {
                return {
                    filteredField: 'prices',
                    filteredValue: (isNaN(parsedValue) ? 0 : parsedValue) * 100,
                    evalFunction: (
                        card: any, internalFilteredField: any, internalFilteredValue: any
                    ) => false,
                }
            }

        }
        case 'set': {
            return {
                filteredField: 'setId',
                filteredValue,
                evalFunction: defaultEvalFunction,
            }
        }
        case 'card': {
            return {
                filteredField: 'cardId',
                filteredValue,
                evalFunction: defaultEvalFunction,
            }
        }
        case 'quantity': {
            const filteredSymbol = splitFilter?.[1 + notOffset]?.toLowerCase()
            let parsedValue = parseInt(splitFilter?.[2 + notOffset] ?? '0')
            filteredValue = (isNaN(parsedValue) ? 0 : parsedValue)
            filteredField = 'items'

            if (filteredSymbol === 'greaterthan') {
                return {
                    filteredField,
                    filteredValue,
                    evalFunction: (
                        card: any, internalFilteredField: any, internalFilteredValue: any
                    ) => (card?.[internalFilteredField]?.length ?? 0) >= internalFilteredValue,
                }
            } else if (filteredSymbol === 'lessthan') {
                return {
                    filteredField,
                    filteredValue,
                    evalFunction: (
                        card: any, internalFilteredField: any, internalFilteredValue: any
                    ) => (card?.[internalFilteredField]?.length ?? 0)  <= internalFilteredValue,
                }
            } else {
                return {
                    filteredField,
                    filteredValue,
                    evalFunction: (
                        card: any, internalFilteredField: any, internalFilteredValue: any
                    ) => false,
                }
            }
        }
        default:
            return {
                filteredField,
                filteredValue,
                evalFunction: defaultEvalFunction,
            }
    }
}

export const filterFunction = ({ filter, cards }: { filter: string, cards: any[] }) => {
    if (filter) {
        const splitFilter = filter?.split('::')

        if (splitFilter.length >= 2) {
            let notOffset = 0
            let notFunction = (value: any) => value
            if (splitFilter?.[0]?.toLowerCase() === 'not') {
                notOffset = 1
                notFunction = (value: any) => !value
            }
            
            const { filteredField, filteredValue, evalFunction } = filters({ splitFilter, notOffset })
            
            return cards.filter(
                (card: any) => notFunction(evalFunction(card, filteredField, filteredValue))
            )
        } else {
            return [ ...cards ]
        }
    } else {
        return [ ...cards ]
    }
}