// Packages
import clsx from 'clsx'
import React, { useState, ReactNode } from 'react'

const Select = ({
    value: initialValue,
    onChange,
    className,
    selectClassName,
    labelTextClassName,
    optionClassName,
    children,
    options,
    disable
}: {
    value: string,
    onChange: (value: string) => void
    className?: string,
    selectClassName?: string,
    labelTextClassName?: string,
    optionClassName?: string,
    children: ReactNode,
    options: { name: string, value: any }[],
    disable?: boolean
}) => {
    // States
    const [value, setValue] = useState(initialValue)

    return (
        <label className={clsx('text-xs', disable ? 'cursor-not-allowed' : '', className)}>
            <p
                className={clsx('mx-2 mb-1', disable ? 'cursor-not-allowed' : '', labelTextClassName ?? '')}
            >
                {children}
            </p>
            <select 
                disabled={disable}
                value={value} 
                onChange={(event) => {
                    if (!disable) {
                        setValue(event.target.value)
                        onChange(event.target.value)
                    }
                }}
                className={clsx('text-base border-2 rounded-lg border-zinc-400 px-2 py-1 w-full', disable ? 'cursor-not-allowed' : '', selectClassName ?? '')}
            >
                {
                    options.map((option, i) => (
                        <option 
                            key={`${option.value}|${i}`} 
                            value={option.value}
                            className={clsx(optionClassName)}
                        >
                            {option.name}
                        </option>
                    ))
                }
            </select>
        </label>
    )
}

export default Select