// Packages
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import clsx from "clsx";
// UI
import TextField from "../ui/TextField";
import Button from "../ui/Button";
import ErrorText from "../ui/ErrorText";
// Contexts
import { LoadingBlockerContext } from "../contexts/loadingBlocker";
import { UserContext } from "../contexts/user";

const SignUp = () => {
    // React Router
    const navigate = useNavigate();
    // States
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    // Contexts
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext)
    const [, userDispatch] = useContext<any>(UserContext)

    return (
        <div className={clsx('flex flex-col gap-4 mx-auto px-16 py-6 md:px-6 md:max-w-[400px]')}>
            <h1 
                className={clsx('text-3xl font-bold text-center')}
            >
                Sign Up
            </h1>
            <TextField
                type="email"
                placeholder="Email"
                value={email}
                onChange={(value) => setEmail(value)}
            >
                Email
            </TextField>
            <TextField
                type="password"
                placeholder="Password"
                value={password}
                onChange={(value) => setPassword(value)}
            >
                Password
            </TextField>
            <TextField
                type="password"
                placeholder="Password"
                value={confirmPassword}
                onChange={(value) => setConfirmPassword(value)}
            >
                Confirm Password
            </TextField>
            {
                error && 
                    <ErrorText className={clsx('mt-1')}>{error}</ErrorText>
            }
            <Button
                className={clsx('my-4')}
                onClick={async () => {
                    if (!email || !password || !confirmPassword) {
                        setError("Please fill out all fields");
                        return;
                    }
                    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
                        setError("Email must be valid");
                        return;
                    }
                    if (!/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{10,}$/.test(password)) {
                        setError("Password must contain at least one upper case, one lower case, a digit, a special character, and be 10 characters long");
                        return;
                    }
                    if (password !== confirmPassword) {
                        setError("Passwords must match");
                        return;
                    }
                
                    loadingBlockerDispatch({
                        type: 'ADD_BLOCKER',
                        payload: 'SIGNUP'
                    })
                    try {
                        try {
                            await signUp({
                                username: email.toLowerCase(),
                                password: password,
                            });
                            loadingBlockerDispatch({
                                type: 'REMOVE_BLOCKER',
                                payload: 'SIGNUP'
                            })
                            navigate('/two-factor-registration');
                            userDispatch({
                                type: 'SET_USER_NAME',
                                payload: email.toLowerCase(),
                            })
                          } catch (error: any) {
                            console.error(error);
                            setError(error.message);
                          }
                    } catch (error: any) {
                        loadingBlockerDispatch({
                            type: 'REMOVE_BLOCKER',
                            payload: 'SIGNUP'
                        })
                    }
                }}
            >
                Sign Up
            </Button>
        </div>
    );
};

export default SignUp;
