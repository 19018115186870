/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
// Helpers
import { base64PlaceHolderGeneratePng } from '../../../helpers/base64Images'
// UI
import StableTableTopTokenGenerator from './StableTableTopTokenGenerator';
import DalleTableTopTokenGenerator from './DalleTableTopTokenGenerator';
// Assets
import BorderImage from '../../../assets/border.png'
import Select from '../../../ui/Select';

const TableTopTokenGenerator = () => {
    const [canvas, setCanvas] = useState<fabric.Canvas>()
    const [canvasId] = useState<string>(`canvas_${uuidv4()}`)
    const [model, setModel] = useState<string>('dalle')
    const [isLoading, setIsLoading] = useState(false)
    const renderRef = useRef<boolean>(false)

    const saveImage = () => {
        const url = canvas ? canvas?.toDataURL({
            format: 'png',
            enableRetinaScaling: true,
            multiplier: 1.024
        }) : ''
        const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
            'download',
            `token.png`,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    }

    const renderNewImage = (imageToAdd: string, isLoadingImage = false) => {
        const objects = canvas?.getObjects();
        if ((objects?.length ?? 0) > 0) {
          const lastObject = objects?.[objects.length - 1];
          canvas?.remove(lastObject ? lastObject : new fabric.Object());
          fabric.Image.fromURL(imageToAdd, (img) => {
            img.scaleToHeight(450);
            img.scaleToWidth(450);
            img.globalCompositeOperation = 'source-atop'
            if (isLoadingImage) {
                img.set({ 
                    selectable: false,
                    hasControls: false,
                })
            }
            canvas?.add(img);
            canvas?.centerObject(img)
            canvas?.renderAll();
        })
        }
      }

    useEffect(() => {
        if (renderRef.current) return
        
        (async () => {
            renderRef.current = true

            const canvas = new fabric.Canvas(canvasId, {
                width: 500,
                height: 500,
                backgroundColor: 'transparent',
            });
            setCanvas(canvas)

            canvas.setOverlayImage(BorderImage, () => {
                canvas.overlayImage && canvas.overlayImage.scaleToWidth(canvas.getWidth())
                const circle = new fabric.Circle({
                    radius: 225,
                    fill: 'white',
                })
                circle.set({ 
                    selectable: false,
                    hasControls: false,
                })
                canvas.add(circle);
                canvas.centerObject(circle)
                
                fabric.Image.fromURL(base64PlaceHolderGeneratePng(), (img) => {
                    img.scaleToHeight(450);
                    img.scaleToWidth(450);
                    img.globalCompositeOperation = 'source-atop'
                    img.set({ 
                        selectable: false,
                        hasControls: false,
                    })
                    canvas.add(img);
                    canvas.centerObject(img)
                })

                canvas.renderAll()
              }, {
                originX: 'left',
                originY: 'top'
              });
        })()
    }, [])

    return (
        <div className={clsx('flex flex-col gap-10 py-6 px-8 w-full')}>
            <Select
                disable={isLoading}
                value={model}
                onChange={(value) => {
                    setModel(value)
                }}
                options={[
                    {
                        name: 'DALLE',
                        value: 'dalle'
                    },
                    {
                        name: 'Stable Diffusion',
                        value: 'stableDiffusion'
                    }
                ]}
            >
                Style Preset
            </Select>
            <div className={clsx('flex flex-col md:flex-row gap-10 w-full')}>
                <div className={clsx('border-4 border-zinc-400 rounded-xl')}>
                    <canvas id={canvasId} className={clsx('h-[300px] w-[300px] mx-auto md:h-[500px] md:w-[500px] md:max-h-[500px]')} />
                </div>
                <div className={clsx('flex flex-col flex-grow gap-4')}>
                    { model === 'stableDiffusion' && 
                        <StableTableTopTokenGenerator 
                            renderNewImage={renderNewImage}
                            saveImage={saveImage}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        /> 
                    }
                    { model === 'dalle' && 
                        <DalleTableTopTokenGenerator 
                            renderNewImage={renderNewImage}
                            saveImage={saveImage}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default TableTopTokenGenerator