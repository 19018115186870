// Packages
import React, { ReactNode } from 'react'
import clsx from 'clsx'

const Chip = ({
    className,
    children,
}: {
    className?: string,
    children?: ReactNode,
}) => {
    return (
        <div 
            className={clsx('h-fit font-bold text-sm rounded-full text-center border', className)}
        >
            {children}
        </div>
    )
}

export default Chip