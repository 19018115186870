/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { ReactNode } from "react";
import clsx from "clsx";
// UI
import TitledText from "../../../ui/TitledText";

const InventoryInfo = ({
    inventoryValue,
    amountSpent,
    amountOfCards,
    totalProfit,
    totalOfUniqueCard,
}: {
    inventoryValue: ReactNode;
    amountSpent: ReactNode;
    amountOfCards: ReactNode;
    totalProfit: ReactNode;
    totalOfUniqueCard: ReactNode;
}) => {
    return (
        <div className={clsx('flex flex-wrap gap-2 divide-x justify-start')}>
            <TitledText
                className={clsx('pl-4')}
                title='Total Value of Inventory'
            >
                {inventoryValue}
            </TitledText>
            <TitledText
                className={clsx('pl-4')}
                title='Total Value of Spent'
            >
                {amountSpent}
            </TitledText>
            <TitledText
                className={clsx('pl-4')}
                title='Total Number of Cards'
            >
                {amountOfCards}
            </TitledText>
            <TitledText
                className={clsx('pl-4')}
                title='Total Profit'
            >
                {totalProfit}
            </TitledText>
            <TitledText
                className={clsx('pl-4')}
                title='Total Unique Cards'
            >
                {totalOfUniqueCard}
            </TitledText>
        </div>
    );
};

export default InventoryInfo;
