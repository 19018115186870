// Packages
import React, { ReactNode } from 'react'
import clsx from 'clsx'

const Button = ({
    onClick,
    className,
    children,
    disable
}: {
    onClick?: () => void
    className?: string,
    children?: ReactNode,
    disable?: boolean
}) => {
    return (
        <button 
            disabled={disable}
            className={clsx('border border-primary rounded-2xl bg-primary w-full text-white px-2 py-2 md:py-1 text-2xl md:text-base', disable ? 'cursor-not-allowed' : '', className ?? '')}
            type='button'
            onClick={() => {
                if (!disable) {
                    if (!onClick) return
                    onClick()
                }
            }}
        >
            {children}
        </button>
    )
}

export default Button