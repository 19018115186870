/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react'
import { generateClient } from 'aws-amplify/api';
// Contexts
import ClientReducer from './reducers'
import initialState from './initialState'
import { UserContext } from '../user'
import { LoadingBlockerContext } from '../loadingBlocker'

export const ClientContext = createContext({})

function Store({ children }: { children: React.ReactNode }) {
    // Context
    const [user] = useContext<any>(UserContext);
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext);
    const [state, dispatch] = useReducer(
        (reducerState: any, action: { type: String; payload?: any }) =>
            ClientReducer(reducerState, action),
        initialState
    )

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    useEffect(() => {
        if (user.token) {
            loadingBlockerDispatch({
                type: 'ADD_PAGE_BLOCKER',
                payload: 'ADD_CLIENT'
            })
            const client = generateClient({
                authToken: user.token
            });
            dispatch({
                type: 'SET_GRAPHQL_CLIENT',
                payload: client
            })
            loadingBlockerDispatch({
                type: 'REMOVE_PAGE_BLOCKER',
                payload: 'ADD_CLIENT'
            })
        }
    }, [user.token])

    return <ClientContext.Provider value={stateVariables}>{children}</ClientContext.Provider>
}

export default Store
