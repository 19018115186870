/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { post } from 'aws-amplify/api'
// Components
import SubscriptionProtection from "../../components/SubscriptionProtection";
import MtgCard from "./components/MtgCard";
// UI
import Button from "../../ui/Button";
import TextField from "../../ui/TextField";
import Loading from "../../ui/Loading";
import NumberField from "../../ui/NumberField";
import Select from "../../ui/Select";
import ErrorText from "../../ui/ErrorText";
import HeaderBackground from "../../ui/HeaderBackground";
import SuccessText from "../../ui/SuccessText";
// Helpers
import { cardConditionSelector } from "./helpers/consts";
import { cardTypeToDisplayName, conditionToDisplayName, generateFinishSelectors } from "./helpers";
import { addMtgCardValidator } from "./helpers/validation";
// Contexts
import { LoadingBlockerContext } from "../../contexts/loadingBlocker";
import { UserContext } from "../../contexts/user";

const AddMtgCardToInventory = () => {
    // States
    const [setId, setSetId] = useState('');
    const [cardId, setCardId] = useState('');
    const [card, setCard]: any = useState({});
    const [quantity, setQuantity]: any = useState(0);
    const [error, setError] = useState('');
    const [addError, setAddError] = useState('');
    const [cardType, setCardType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [scryfallUrl, setScryfallUrl] = useState('');
    const [boughtDate, setBoughtDate] = useState('');
    const [boughtAt, setBoughtAt] = useState('');
    const [condition, setCondition] = useState('');
    // Contexts
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext)
    const [user] = useContext<any>(UserContext)

    return (
        <SubscriptionProtection
            resourceDisplayName="MTG Card Inventory"
            resourceKey="mtgInventoryEnabled"
        >
            <HeaderBackground className='text-2xl'>
                <NavLink to='/mtg-card-inventory'>Back</NavLink>
            </HeaderBackground>
            <div className={clsx('py-6 px-8')}>
                <div className={clsx('flex gap-6 justify-between px-4 border-b-2 pb-4 mb-4 border-zinc-400')}>
                    <h2 className={clsx('font-semibold text-xl')}>Add MTG</h2>
                </div>
                <div className={clsx('flex flex-col gap-6 justify-between px-4 mx-6 border-b-2 pb-4 mb-4 border-zinc-400')}>
                    <h3 className={clsx('text-lg')}>Search for MTG Card</h3>
                    <TextField
                        type="text"
                        placeholder="Set ID (eg. CLB)"
                        value={setId}
                        onChange={(value) => setSetId(value)}
                    >
                        Set ID
                    </TextField>
                    <TextField
                        type="text"
                        placeholder="Card ID (eg. 001)"
                        value={cardId}
                        onChange={(value) => setCardId(value.replace(/^0+/, ''))}
                    >
                        Card ID
                    </TextField>
                    {error && 
                        <ErrorText className={clsx('mt-8')}>{error}</ErrorText>
                    }
                    <Button 
                        className={clsx('mb-4')} 
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                setScryfallUrl(`https://api.scryfall.com/cards/${setId}/${cardId}`)
                                const response = await fetch(
                                    `https://api.scryfall.com/cards/${setId}/${cardId}`, 
                                    {
                                        method: "GET",
                                    }
                                )
                                const data = await response.json();
                                if (data.object === 'error') {
                                    setError(data.details)
                                    setQuantity(0)
                                } else {
                                    setError('')
                                    setCard({ ...data })
                                    setIsLoading(false)
                                    setQuantity(1)
                                }
                                setCardType('')
                                setSuccessMessage('')
                                setAddError('')
                                setIsLoading(false)
                            } catch (error: any) {
                                setError(error.message)
                                console.error(error)
                                setIsLoading(false)
                            }
                        }
                    }>
                        Search
                    </Button>
                </div>
                {
                    Object.keys(card).length === 0 && !isLoading &&
                        <center>
                            <h4 className={clsx('mx-auto font-semibold text-lg')}>Search for a card above before adding a card</h4>
                        </center>
                }
                {
                    isLoading &&
                        <div className={clsx('w-28 mx-auto')}>
                            <Loading color='primary' />
                        </div>
                }
                {
                    Object.keys(card).length > 0 && !isLoading &&
                        <div className={clsx('px-10')}>
                            <div className={clsx('flex flex-col gap-4 lg:flex-row')}>
                                <MtgCard 
                                    name={card?.name}
                                    imageUrls={
                                        card?.card_faces?.length > 1 && card?.card_faces?.[0]?.image_uris?.normal ? (
                                            [
                                                ...card.card_faces.map((face: any) => {
                                                    return face?.image_uris?.normal
                                                })
                                            ]
                                        ) : (
                                            card?.image_uris ? [card?.image_uris?.normal] : []
                                        )
                                    }
                                    price={cardType ? card.prices?.[cardType]: undefined}
                                    cardType={cardType ? cardTypeToDisplayName({ cardType }) : undefined}
                                    dateBought={boughtDate ?? undefined}
                                    boughtAt={boughtAt ?? undefined}
                                    power={card?.power}
                                    toughness={card?.toughness}
                                    colors={card?.colors?.join(', ')}
                                    cmc={card?.cmc}
                                    rarity={card?.rarity}
                                    legalities={Object.keys(card?.legalities).filter((legality) => {
                                        return card?.legalities[legality] === 'legal';
                                    }).join(', ')}
                                    condition={conditionToDisplayName({ condition })}
                                    keywords={card?.keywords?.join(', ')}
                                    type={card?.type_line}
                                />
                                <div className={clsx('flex flex-col gap-4 lg:flex-row flex-wrap h-fit')}>
                                    <NumberField
                                        className={clsx('h-fit')}
                                        placeholder="0"
                                        value={quantity}
                                        onChange={(value) => setQuantity(value)}
                                    >
                                        How many to add to inventory?
                                    </NumberField>
                                    <Select
                                        className={clsx('h-fit')}
                                        value={cardType}
                                        onChange={(value) => {
                                            setCardType(value)
                                        }}
                                        options={
                                            [
                                                {
                                                    name: 'None',
                                                    value: ''
                                                },
                                                ...(generateFinishSelectors({ finishes: card?.finishes }))
                                            ]
                                        }
                                    >
                                        Card Type
                                    </Select>
                                    <Select
                                        className={clsx('h-fit')}
                                        value={condition}
                                        onChange={(value) => {
                                            setCondition(value)
                                        }}
                                        options={cardConditionSelector}
                                    >
                                        Condition
                                    </Select>
                                    <TextField
                                        className={clsx('h-fit')}
                                        type="text"
                                        placeholder="01-01-2025"
                                        value={boughtDate}
                                        onChange={(value) => setBoughtDate(value)}
                                    >
                                        Date Bought
                                    </TextField>
                                    <TextField
                                        className={clsx('h-fit')}
                                        type="text"
                                        placeholder="1.00"
                                        value={boughtAt}
                                        onChange={(value) => setBoughtAt(value)}
                                    >
                                        Price Bought At
                                    </TextField>
                                </div>
                            </div>
                            {addError && 
                                <ErrorText className={clsx('mt-8')}>{addError}</ErrorText>
                            }
                            {successMessage && 
                                <SuccessText className={clsx('mt-8')}>{successMessage}</SuccessText>
                            }
                            <Button 
                                className={clsx(addError ? 'mt-2' : 'mt-8')}
                                onClick={async () => {
                                    setSuccessMessage('')
                                    const validation = addMtgCardValidator({
                                        cardType,
                                        quantity,
                                        card,
                                        condition,
                                        boughtDate,
                                        boughtAt,
                                    })
                                    if (!validation.isValid) {
                                        setAddError(validation.error)
                                    } else {
                                        loadingBlockerDispatch({
                                            type: 'ADD_BLOCKER',
                                            payload: 'ADD_CARD_TO_INVENTORY'
                                        })
                                        setAddError('')
                                        try {
                                            const addMtgCardResponse = post({
                                                apiName: "api",
                                                path: '/add-mtg-card',
                                                options: {
                                                    headers: {
                                                        Authorization: user.token ?? '',
                                                    },
                                                    body: {
                                                        scryfallUrl,
                                                        cardId,
                                                        setId,
                                                        priceType: cardType,
                                                        condition,
                                                        quantity: parseInt(quantity),
                                                        priceBought: parseInt(boughtAt.replace('.', '').replace(',', '')).toLocaleString(),
                                                        dateBought: new Date(boughtDate).toISOString(),
                                                    }
                                                }
                                            });
                                            await addMtgCardResponse.response
                                            setSuccessMessage('Successfully added card to inventory')
                                        } catch (error: any) {
                                            console.error(error)
                                            setSuccessMessage('')
                                            setAddError(error.errors[0]?.message)
                                        }
                                        loadingBlockerDispatch({
                                            type: 'REMOVE_BLOCKER',
                                            payload: 'ADD_CARD_TO_INVENTORY'
                                        })
                                    }
                                }}
                            >
                                Add Card to Collection
                            </Button>
                        </div>
                }
            </div>
        </SubscriptionProtection>
    );
};

export default AddMtgCardToInventory;
