// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const TableHeadElement = ({ 
    className,
    children,
}: { 
    className?: string,
    children: ReactNode
}) => {
    return (
        <th className={clsx(className)}>
            {children}
        </th> 
    )
}

export default TableHeadElement