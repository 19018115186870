// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const HeaderBackground = ({ children, className }: { children: ReactNode, className: string }) => {
    return (
        <nav className={clsx('bg-primary text-white w-full flex justify-between gap-4 px-8 py-4 h-fit md:h-18', className)}>
            {children}
        </nav>
    )
}

export default HeaderBackground