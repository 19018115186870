// Packages
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { confirmSignUp } from "aws-amplify/auth";
// UI
import TextField from "../ui/TextField";
import Button from "../ui/Button";
import ErrorText from "../ui/ErrorText";
// Contexts
import { LoadingBlockerContext } from "../contexts/loadingBlocker";
import { UserContext } from "../contexts/user";

const SignUp = () => {
    // React Router
    const navigate = useNavigate();
    // States
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    // Contexts
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext)
    const [user] = useContext<any>(UserContext)

    return (
        <div className={clsx('flex flex-col gap-4 mx-auto px-16 py-6 md:px-6 md:max-w-[400px]')}>
            <h1 
                className={clsx('text-3xl font-bold text-center')}
            >
                Sign Up
            </h1>
            <TextField
                type="text"
                placeholder="Code"
                value={code}
                onChange={(value) => setCode(value)}
            >
                Code
            </TextField>
            {
                error && 
                    <ErrorText className={clsx('mt-1')}>{error}</ErrorText>
            }
            <Button
                className={clsx('my-4')}
                onClick={async () => {
                    loadingBlockerDispatch({
                        type: 'ADD_BLOCKER',
                        payload: 'SIGNUP'
                    })
                    try {
                        try {
                            await confirmSignUp({
                                username: user.userName,
                                confirmationCode: code,
                            })
                            loadingBlockerDispatch({
                                type: 'REMOVE_BLOCKER',
                                payload: 'SIGNUP'
                            })
                            navigate('/login');
                          } catch (error: any) {
                            console.error(error);
                            setError(error.message);
                          }
                    } catch (error: any) {
                        loadingBlockerDispatch({
                            type: 'REMOVE_BLOCKER',
                            payload: 'SIGNUP'
                        })
                    }
                }}
            >
                Sign Up
            </Button>
        </div>
    );
};

export default SignUp;
