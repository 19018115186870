// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const ErrorText = ({ className, children }: { className?: string, children: ReactNode }) => {
    return (
        <p className={clsx('text-red-500 text-sm', className)}>{children}</p>  
    )
}

export default ErrorText