/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSubscription = /* GraphQL */ `query GetSubscription($userId: String!) {
  getSubscription(userId: $userId) {
    userId
    subscriptionId
    tokenGeneratorEnabled
    mtgInventoryEnabled
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubscriptionQueryVariables,
  APITypes.GetSubscriptionQuery
>;
export const getMtgCardInventoryEntry = /* GraphQL */ `query GetMtgCardInventoryEntry($userId: String!, $setCardId: String!) {
  getMtgCardInventoryEntry(userId: $userId, setCardId: $setCardId) {
    userId
    setCardId
    scryFallId
    oracleId
    multiverseIds
    tcgPlayerId
    cardMarketId
    cardName
    imageUrl
    prices
    colors
    power
    toughness
    manaCost
    cmc
    priceType
    items {
      id
      condition
      priceBought
      dateBought
      isInDeck
      deckId
      __typename
    }
    setId
    setName
    cardId
    legalities
    rarity
    keywords
    type
    isDeleted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMtgCardInventoryEntryQueryVariables,
  APITypes.GetMtgCardInventoryEntryQuery
>;
export const getMtgCardInventoryEntries = /* GraphQL */ `query GetMtgCardInventoryEntries(
  $userId: String!
  $limit: Int
  $nextToken: String
) {
  getMtgCardInventoryEntries(
    userId: $userId
    limit: $limit
    nextToken: $nextToken
  ) {
    data {
      userId
      setCardId
      scryFallId
      oracleId
      multiverseIds
      tcgPlayerId
      cardMarketId
      cardName
      imageUrl
      prices
      colors
      power
      toughness
      manaCost
      cmc
      priceType
      items {
        id
        condition
        priceBought
        dateBought
        isInDeck
        deckId
        __typename
      }
      setId
      setName
      cardId
      legalities
      rarity
      keywords
      type
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMtgCardInventoryEntriesQueryVariables,
  APITypes.GetMtgCardInventoryEntriesQuery
>;
export const getMtgCard = /* GraphQL */ `query GetMtgCard($setCardId: String!) {
  getMtgCard(setCardId: $setCardId) {
    setCardId
    scryFallId
    oracleId
    multiverseIds
    tcgPlayerId
    cardMarketId
    cardName
    imageUrl
    prices
    colors
    power
    toughness
    manaCost
    cmc
    priceType
    setId
    setName
    cardId
    legalities
    rarity
    keywords
    type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMtgCardQueryVariables,
  APITypes.GetMtgCardQuery
>;
export const getMtgCardInventoryEntryByIndex = /* GraphQL */ `query GetMtgCardInventoryEntryByIndex($setCardId: String!) {
  getMtgCardInventoryEntryByIndex(setCardId: $setCardId) {
    userId
    setCardId
    scryFallId
    oracleId
    multiverseIds
    tcgPlayerId
    cardMarketId
    cardName
    imageUrl
    prices
    colors
    power
    toughness
    manaCost
    cmc
    priceType
    items {
      id
      condition
      priceBought
      dateBought
      isInDeck
      deckId
      __typename
    }
    setId
    setName
    cardId
    legalities
    rarity
    keywords
    type
    isDeleted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMtgCardInventoryEntryByIndexQueryVariables,
  APITypes.GetMtgCardInventoryEntryByIndexQuery
>;
