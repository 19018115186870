export const generateFinishSelectors = ({ finishes }: { finishes: [string] }) => {
    return finishes.map((finish: string) => {
        if (finish === 'nonfoil') {
            return {
                name: 'Standard',
                value: 'usd'
            }
        } else if (finish === 'foil') {
            return {
                name: 'Foil',
                value: 'usd_foil'
            }
        } else if (finish === 'etched') {
            return {
                name: 'Etched',
                value: 'usd_etched'
            }
        } else {
            return {
                name: 'Standard',
                value: 'usd'
            }
        }
    })
}

export const cardTypeToDisplayName = ({ cardType }: { cardType: string }) => {
    switch (cardType) {
        case 'usd':
            return 'Standard'
        case 'usd_foil':
            return 'Foil'
        case 'usd_etched':
            return 'Etched'
        default:
            return 'Standard'
    }
}
export const displayNameToCardType = ({ displayName }: { displayName: string }) => {
    const cardType = displayName.toLowerCase()

    switch (cardType) {
        case 'standard':
            return 'usd'
        case 'foil':
            return 'usd_foil'
        case 'etched':
            return 'usd_etched'
        default:
            return 'usd'
    }
}

export const conditionToDisplayName = ({ condition }: { condition: string }) => {
    switch (condition) {
        case 'mint':
            return 'Mint'
        case 'nearMint':
            return 'Near Mint'
        case 'lightlyPlayed':
            return 'Lightly Played'
        case 'moderatelyPlayed':
            return 'Moderately Played'
        case 'heavilyPlayed':
            return 'Heavily Played'
        case 'damaged':
            return 'Damaged'
        default:
            return ''
    }
}
export const rarityScore = ({ rarity }: { rarity: string }) => {
    return {
        special: 1,
        common: 2,
        uncommon: 3,
        rare: 4,
        mythic: 5,
        shifted: 6,
    }[rarity]
}