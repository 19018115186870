/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext } from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
// UI
import Header from "../ui/Header";
import Button from "../ui/Button";
// Contexts
import { ProductsContext } from "../contexts/products";

const Home = () => {
    // Contexts
    const [products] = useContext<any>(ProductsContext)

    return (
        <>
            <Header />
            <div className={clsx('py-6 px-8 flex flex-col sm:flex-row flex-wrap gap-6 sm:gap-10 w-full')}>
                {
                    Object.keys(products).length === 0 && 
                        <h3 className={clsx('font-semibold text-xl')}>
                            You do not have access to any products
                        </h3>
                }
                {
                    Object.keys(products).map((product: any, i: number) => {
                        return (
                            <NavLink key={`${product}-${i}`} to={products[product].path} className={clsx('border-2 border-zinc-400 rounded-lg p-3 w-full aspect-square sm:w-72 sm:h-72 flex flex-col justify-between')}>
                                <div>
                                    <h3 className={clsx('border-b border-zinc-300 pb-3 px-3 font-semibold text-xl')}>{products[product].homePage.title}</h3>
                                    <p className={clsx('px-3 pt-2 pb-6')}>{products[product].homePage.description}</p>
                                </div>
                                <Button className={clsx('float-right right-0 w-1/3')} onClick={() => {}}>
                                    Go To
                                </Button>
                            </NavLink>
                        )
                    })
                }
            </div>
        </>
    );
};

export default Home;
