/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import clsx from "clsx";
import { v4 as uuidv4 } from 'uuid';
// Queries
import { getMtgCardInventoryEntry } from "../../graphql/queries";
import { setMtgCardInventoryEntry } from "../../graphql/mutations";
// Components
import SubscriptionProtection from "../../components/SubscriptionProtection";
import MtgCard from "./components/MtgCard";
// UI
import Button from "../../ui/Button";
import HeaderBackground from "../../ui/HeaderBackground";
import Select from "../../ui/Select";
import TextField from "../../ui/TextField";
import ErrorText from "../../ui/ErrorText";
import SuccessText from "../../ui/SuccessText";
// Contexts
import { LoadingBlockerContext } from "../../contexts/loadingBlocker";
import { UserContext } from "../../contexts/user";
import { ClientContext } from "../../contexts/clients";
// Helpers
import { cardConditionSelector } from "./helpers/consts";
import moment from "moment";

const EditMtgCardToInventory = () => {
    // States
    const [card, setCard]: any = useState({});
    const [errors, setErrors]: any = useState({});
    const [successMessage, setSuccessMessage]: any = useState('');
    // Contexts
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext)
    const [user] = useContext<any>(UserContext)
    const [clients] = useContext<any>(ClientContext)
    // Refs
    const didGetCard = useRef<any>(false);
    // Params
    const { setId, cardId, finish } = useParams()

    useEffect(() => {
        console.log(card)
    }, [card])
    useEffect(() => {
        console.log(errors)
    }, [errors])

    useEffect(() => {
        if (didGetCard?.current) return
        didGetCard.current = true

        const asyncWrapper = async () => {
            loadingBlockerDispatch({
                type: 'ADD_BLOCKER',
                payload: 'GET_CARD_ON_EDIT_PAGE'
            })
            try {
                const response = await clients.graphql.graphql({
                    query: getMtgCardInventoryEntry,
                    variables: {
                        userId: user.userId,
                        setCardId: `${setId}#${cardId}#${finish}`,
                    }
                })
                setCard(response?.data?.getMtgCardInventoryEntry)                
            } catch (error) {
                setCard({})
                console.error(error)
            }

            loadingBlockerDispatch({
                type: 'REMOVE_BLOCKER',
                payload: 'GET_CARD_ON_EDIT_PAGE'
            })
        }

        asyncWrapper()
    }, [])

    return (
        <SubscriptionProtection
            resourceDisplayName="MTG Card Inventory"
            resourceKey="mtgInventoryEnabled"
        >
            <HeaderBackground className='text-2xl'>
                <NavLink to='/mtg-card-inventory'>Back</NavLink>
            </HeaderBackground>
            <div className={clsx('py-6 px-8')}>
                <div className={clsx('flex gap-6 justify-between px-4 border-b-2 pb-4 mb-4 border-zinc-400')}>
                    <h2 className={clsx('font-semibold text-xl')}>Edit MTG Card</h2>
                </div>
                <div className={clsx('flex flex-col gap-6 justify-between px-4 mx-6 pb-4 mb-4')}>
                    {
                        Object.keys(card).length === 0 &&
                            <center>
                                <h3 className={clsx('mx-auto font-semibold text-lg')}>Card does not exist</h3>
                            </center>
                    }
                    {
                        Object.keys(card).length > 0 &&
                            <>
                                <MtgCard 
                                    name={card?.cardName}
                                    imageUrls={card.imageUrl}
                                    price={(card?.price?.[(card?.price?.length ?? 1) - 1] ?? 0)}
                                    cardType={card?.priceType}
                                    power={card?.power}
                                    toughness={card?.toughness}
                                    colors={card?.colors?.join(', ')}
                                    cmc={card?.cmc}
                                    rarity={card?.rarity}
                                    legalities={card?.legalities?.join(', ')}
                                    keywords={card?.keywords?.join(', ')}
                                    type={card?.type_line}
                                />
                                <h2 className={clsx('font-semibold text-lg pb-2 border-b-2 border-zinc-400')}>Owned Cards</h2>
                                <div className={clsx('flex flex-wrap gap-4 h-fit w-full overflow-x-scroll justify-evenly')}>
                                    {
                                        (card?.items ?? [])?.map((item: any, i: number) => {
                                            return (
                                                <div className={clsx('w-36 mb-4')} key={item?.id}>
                                                    <Select
                                                        className={clsx('h-fit')}
                                                        value={card.items[i].condition}
                                                        onChange={(value: any) => {
                                                            setCard(
                                                                (prev: any) => {
                                                                    let temp = { ...prev, items: [...prev.items] }
                                                                    temp.items[i] = { ...temp.items[i], condition: value }
                                                                    return temp
                                                                }
                                                            )
                                                        }}
                                                        options={cardConditionSelector}
                                                    >
                                                        Condition
                                                    </Select>
                                                    <TextField
                                                        className={clsx('h-fit')}
                                                        type="text"
                                                        placeholder='01-01-2025'
                                                        value={moment(card.items[i].dateBought).format('MM-DD-YYYY')}
                                                        onChange={(value: any) => {
                                                            if (!/\d{2}-\d{2}-\d{4}/.test(value)) {
                                                                setErrors((prev: any) => ({ ...prev, [item?.id]: { ...prev?.[item?.id], date: { isError: true, message: 'Please enter a valid date in MM-DD-YYYY format for bought at' } } }))
                                                            } else {
                                                                setErrors((prev: any) => ({ ...prev, [item?.id]: { ...prev?.[item?.id], date: { isError: false, message: '' } } }))
                                                                setCard(
                                                                    (prev: any) => {
                                                                        let temp = { ...prev, items: [...prev.items] }
                                                                        let tempDate = ''
                                                                        try {
                                                                            tempDate = new Date(value).toISOString()
                                                                        } catch {
                                                                            tempDate = ''
                                                                        }
                                                                        temp.items[i] = { ...temp.items[i], dateBought: tempDate }
                                                                        return temp
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        Date Bought
                                                    </TextField>
                                                    {
                                                        errors?.[item?.id]?.date?.isError &&
                                                            <ErrorText className={clsx('my-1')}>{errors?.[item?.id]?.date?.message}</ErrorText>
                                                    }
                                                    <TextField
                                                        className={clsx('h-fit')}
                                                        type="text"
                                                        placeholder='0.00'
                                                        value={(card.items[i].priceBought/100).toString()}
                                                        onChange={(value: any) => {
                                                            if (!/^[+-]?\d+(\.\d+)?$/.test(value)) {
                                                                setErrors((prev: any) => ({ ...prev, [item?.id]: { ...prev?.[item?.id], price: { isError: true, message: 'Price bought must be a number' } } }))
                                                            } else {
                                                                setErrors((prev: any) => ({ ...prev, [item?.id]: { ...prev?.[item?.id], price: { isError: false, message: '' } } }))
                                                                setCard(
                                                                    (prev: any) => {
                                                                        let temp = { ...prev, items: [...prev.items] }
                                                                        temp.items[i] = { ...temp.items[i], priceBought: value * 100 }
                                                                        return temp
                                                                    }
                                                                )
                                                            }
                                                            
                                                        }}
                                                    >
                                                        Price Bought At
                                                    </TextField>
                                                    {
                                                        errors?.[item?.id]?.price?.isError &&
                                                            <ErrorText className={clsx('mt-1')}>{errors?.[item?.id]?.price?.message}</ErrorText>
                                                    }
                                                    <Button 
                                                        className={clsx('mt-2')}
                                                        onClick={() => {
                                                            setErrors((prev: any) => {
                                                                let temp = { ...prev }
                                                                delete temp[item?.id]
                                                                return temp
                                                            })
                                                            setCard(
                                                                (prev: any) => {
                                                                    let temp = { ...prev, items: [...prev.items] }
                                                                    temp.items = temp.items.filter((item: any, j: number) => j !== i)
                                                                    return temp
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            )
                                        })
                                    }
                                    <Button
                                        onClick={() => {
                                            const id = uuidv4()
                                            setErrors((prev: any) => ({ ...prev, [id]: { date: { isError: true, message: 'Please enter a valid date in MM-DD-YYYY format for bought at' }, price: { isError: true, message: 'Price bought must be a number' } } }))
                                            setCard(
                                                (prev: any) => {
                                                    let temp = { ...prev, items: [...prev.items] }
                                                    temp.items.push({
                                                        id,
                                                        priceBought: 0,
                                                        dateBought: '',
                                                        condition: '',
                                                        isInDeck: false,
                                                        deckId: '',
                                                    })
                                                    return temp
                                                }
                                            )
                                        }}
                                    >
                                        Add Card
                                    </Button>
                                    <Button 
                                        className={clsx('mt-2')}
                                        onClick={async () => {
                                            setSuccessMessage('')
                                            if (Object.values(errors).some((error: any) => error?.price?.isError || error?.date?.isError)) {
                                                // Nothing
                                            } else {
                                                loadingBlockerDispatch({
                                                    type: 'ADD_BLOCKER',
                                                    payload: 'EDIT_CARD_IN_INVENTORY'
                                                })
                                                try {
                                                    let tempCard: any = { userId: user.userId, setCardId: card?.setCardId, items: [] as any[] }

                                                    tempCard.items = card.items.map((item: any) => {
                                                        return {
                                                            id: item.id,
                                                            priceBought: item.priceBought,
                                                            dateBought: item.dateBought,
                                                            condition: item.condition,
                                                            isInDeck: false,
                                                            deckId: '',
                                                        }
                                                    })

                                                    delete tempCard['__typename']

                                                    await clients.graphql.graphql({
                                                        query: setMtgCardInventoryEntry,
                                                        variables: {
                                                            input: {
                                                                ...tempCard
                                                            }
                                                        }
                                                    })
                                                    setSuccessMessage('Successfully edited card')
                                                } catch (error: any) {
                                                    console.error(error)
                                                    setSuccessMessage('Failed to save')
                                                }
                                                loadingBlockerDispatch({
                                                    type: 'REMOVE_BLOCKER',
                                                    payload: 'EDIT_CARD_IN_INVENTORY'
                                                })
                                            }
                                        }}
                                    >
                                        Update
                                    </Button>
                                    {successMessage && 
                                        <SuccessText className={clsx('mt-2')}>{successMessage}</SuccessText>
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>
        </SubscriptionProtection>
    );
};

export default EditMtgCardToInventory;
