// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'
import TableBodyColumn from './TableBodyColumn'

const TableBodyRow = ({ 
    className,
    columns,
    id,
}: { 
    className?: string;
    columns: ReactNode[];
    id: string;
}) => {
    return (
        <tr className={clsx(className)}>
            {columns.map((column, i) => {
                return (
                    <TableBodyColumn key={`table-column-${id}-${i}`}>
                        {column}
                    </TableBodyColumn>
                )
            })}
        </tr> 
    )
}

export default TableBodyRow