// Packages
import React, { createContext, useReducer, useMemo } from 'react'
// Contexts
import ClientReducer from './reducers'
import initialState from './initialState'

export const LoadingBlockerContext = createContext({})

function Store({ children }: { children: React.ReactNode }) {
    // Context
    const [state, dispatch] = useReducer(
        (reducerState: any, action: { type: String; payload?: any }) =>
            ClientReducer(reducerState, action),
        initialState
    )

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    return <LoadingBlockerContext.Provider value={stateVariables}>{children}</LoadingBlockerContext.Provider>
}

export default Store
