// Packages
import React, { Fragment, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
// Pages
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Home from "../pages/Home";
import SignUp from "../pages/Signup";
import TwoFactorRegistration from "../pages/TwoFactorRegistration";
// Components
import ProtectedLayout from "./ProtectedLayout";
import ToHomeOnAuthLayout from "./ToHomeOnAuthLayout";
// Contexts
import { ProductsContext } from "../contexts/products";
// Helpers
import { products as productsObject } from "../helpers/products";
import SubscriptionProtection from "./SubscriptionProtection";

const Paths = () => {
    // Contexts
    const [products] = useContext<any>(ProductsContext)

    return (
        <Router>
            <Routes>
                <Route 
                    element={
                        <ProtectedLayout />
                    }
                >
                    <Route path='/' element={<Home />} /> 
                    <Route path='/logout' element={<Logout />} /> 
                    <Route
                        path="*"
                        element={<></>}
                    />
                    {
                        Object.keys(productsObject).map((productKey: any) => {
                            if (!products[productKey]) {
                                return (
                                    <Fragment key={productsObject?.[productKey]?.pageTitle} >
                                        <Route 
                                            path={productsObject?.[productKey]?.path} 
                                            element={
                                                <SubscriptionProtection
                                                    resourceDisplayName={productsObject?.[productKey]?.pageTitle}
                                                    resourceKey={`${productKey}Enabled`}
                                                >
                                                    <></>
                                                </SubscriptionProtection>
                                            } 
                                        />
                                        {
                                            Object.values(productsObject?.[productKey]?.subPaths).map((subPath: any) => {
                                                return (
                                                    <Route 
                                                        key={subPath.path}
                                                        path={subPath.path} 
                                                        element={
                                                            <SubscriptionProtection
                                                                resourceDisplayName={productsObject?.[productKey]?.pageTitle}
                                                                resourceKey={`${productKey}Enabled`}
                                                            >
                                                                <></>
                                                            </SubscriptionProtection>
                                                        } 
                                                    />
                                                )
                                            })
                                        }
                                    </Fragment>
                                )
                            }

                            return (
                                <Fragment key={products?.[productKey]?.pageTitle} >
                                    <Route 
                                        path={products?.[productKey]?.path} 
                                        element={products?.[productKey]?.component} 
                                    />
                                    {
                                        Object.values(products?.[productKey]?.subPaths).map((subPath: any) => {
                                            return (
                                                <Route 
                                                    key={subPath.path}
                                                    path={subPath.path} 
                                                    element={subPath.component} 
                                                />
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }
                </Route>
                <Route 
                    element={
                        <ToHomeOnAuthLayout />
                    }
                >
                    <Route path='/login' element={<Login />} /> 
                    <Route path='/signup' element={<SignUp />} /> 
                    <Route path='/two-factor-registration' element={<TwoFactorRegistration />} /> 
                </Route>
            </Routes>
        </Router>
    )
}

export default Paths