// Packages
import React from 'react'
// UI
import OrderBy from "../../../../ui/OrderBy";

const Header = ({ 
    setOrderBy,
    orderBy,
}: { 
    setOrderBy: (orderBy: string) => void;
    orderBy: string;
}) => {
    return [
        <>Image</>,
        <OrderBy
            orderBy="name"
            setOrderBy={setOrderBy}
            orderByState={orderBy}
        >
            Name
        </OrderBy>,
        <>Finish</>,
        <OrderBy
            orderBy="rarity"
            setOrderBy={setOrderBy}
            orderByState={orderBy}
        >
            Rarity
        </OrderBy>,
        <OrderBy
            orderBy="price"
            setOrderBy={setOrderBy}
            orderByState={orderBy}
        >
            Price
        </OrderBy>,
        <OrderBy
            orderBy="spent"
            setOrderBy={setOrderBy}
            orderByState={orderBy}
        >
            Total Spent
        </OrderBy>,
        <OrderBy
            orderBy="totalPrice"
            setOrderBy={setOrderBy}
            orderByState={orderBy}
        >
            Total Price
        </OrderBy>,
        <OrderBy
            orderBy="profit"
            setOrderBy={setOrderBy}
            orderByState={orderBy}
        >
            Profit
        </OrderBy>,
        <OrderBy
            orderBy="quantity"
            setOrderBy={setOrderBy}
            orderByState={orderBy}
        >
            Quantity
        </OrderBy>,
        <></>,
        <></>,
    ]
}

export default Header