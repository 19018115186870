/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react'
// Contexts
import ClientReducer from './reducers'
import initialState from './initialState'
import { SubscriptionContext } from '../subscription'
import { LoadingBlockerContext } from '../loadingBlocker'
// Helpers
import { Product, products, subscriptionKeyToProductKey } from '../../helpers/products'

export const ProductsContext = createContext({})

function Store({ children }: { children: React.ReactNode }) {
    // Context
    const [subscriptions] = useContext<any>(SubscriptionContext);
    const [, loadingBlockerDispatch] = useContext<any>(LoadingBlockerContext);
    const [state, dispatch] = useReducer(
        (reducerState: any, action: { type: String; payload?: any }) =>
            ClientReducer(reducerState, action),
        initialState
    )

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    useEffect(() => {
        if (Object.keys(subscriptions).length > 0) {
            loadingBlockerDispatch({
                type: 'ADD_PAGE_BLOCKER',
                payload: 'GENERATE_PRODUCTS'
            })

            let tempProducts: { [key: string]: Product } = {}
            for (const subscription in subscriptions) {
                if (subscriptions[subscription]) {
                    const productKey = subscriptionKeyToProductKey({ subscriptionKey: subscription })
                    tempProducts[productKey] = products[productKey]
                }
            }
            dispatch({
                type: 'SET',
                payload: tempProducts
            })

            loadingBlockerDispatch({
                type: 'REMOVE_PAGE_BLOCKER',
                payload: 'GENERATE_PRODUCTS'
            })
        }
    }, [subscriptions])

    return <ProductsContext.Provider value={stateVariables}>{children}</ProductsContext.Provider>
}

export default Store
