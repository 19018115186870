// Packages
import { ReactNode } from 'react'
import clsx from 'clsx'

const SuccessText = ({ className, children }: { className?: string, children: ReactNode }) => {
    return (
        <p className={clsx('text-green-500 text-sm', className)}>{children}</p>  
    )
}

export default SuccessText