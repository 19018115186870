// Packages
import { useState, useEffect } from 'react';
import clsx from 'clsx'
// UI
import CarouselWithButtons from '../../../ui/CarouselWithButtons';
import CollapseMenu from '../../../ui/CollapseMenu';
import TitledText from '../../../ui/TitledText';

const MtgCard = ({ 
    name,
    imageUrls,
    price,
    cardType,
    dateBought,
    boughtAt,
    power,
    toughness,
    colors,
    cmc,
    rarity,
    legalities,
    condition,
    keywords,
    type,
    mintQuantity,
    nearMintQuantity,
    lightlyPlayedQuantity,
    moderatelyPlayedQuantity,
    heavilyPlayedQuantity,
    damagedQuantity,
    className,
}: { 
    name: string;
    imageUrls: string[];
    price?: string;
    cardType?: string;
    dateBought?: string;
    boughtAt?: string
    power?: string;
    toughness?: string;
    colors?: string;
    cmc?: string;
    rarity?: string;
    legalities?: string;
    condition?: string;
    keywords?: string;
    type?: string;
    mintQuantity?: string;
    nearMintQuantity?: string;
    lightlyPlayedQuantity?: string;
    moderatelyPlayedQuantity?: string;
    heavilyPlayedQuantity?: string;
    damagedQuantity?: string;
    className?: string;
}) => {
    // States
    const [hasExtras, setHasExtra] = useState<string|undefined>('')
    const [hasPriceDetails, setHasPriceDetails] = useState<string|undefined>('')
    const [hasCardDetails, setHasCardDetails] = useState<string|undefined>('')
    const [hasQuantityDetails, setHasQuantityDetails] = useState<string|undefined>('')

    useEffect(() => {
        setHasExtra(
            price || 
            cardType || 
            dateBought || 
            power || 
            toughness || 
            colors || 
            cmc || 
            rarity || 
            legalities || 
            condition || 
            keywords || 
            type ||
            mintQuantity ||
            nearMintQuantity ||
            lightlyPlayedQuantity || 
            moderatelyPlayedQuantity || 
            heavilyPlayedQuantity ||
            damagedQuantity
        )
        setHasPriceDetails(
            price || 
            cardType || 
            dateBought || 
            condition
        )
        setHasCardDetails(
            power || 
            toughness || 
            colors || 
            cmc || 
            rarity || 
            legalities || 
            keywords || 
            type
        )
        setHasQuantityDetails(
            mintQuantity ||
            nearMintQuantity ||
            lightlyPlayedQuantity || 
            moderatelyPlayedQuantity || 
            heavilyPlayedQuantity ||
            damagedQuantity
        )
    }, [price, cardType, dateBought, power, 
        toughness, colors, cmc, rarity, legalities, 
        condition, keywords, type, mintQuantity,
        nearMintQuantity, lightlyPlayedQuantity, 
        moderatelyPlayedQuantity, heavilyPlayedQuantity,
        damagedQuantity
    ])

    return (
        <div className={clsx(
            'flex flex-col md:flex-row justify-center gap-1 bg-base-200 rounded-lg py-3 md:py-5 md:mt-6 px-4', className
        )}>
            <div className={clsx('md:w-1/2')}>
                <h4 className={clsx('font-bold text-2xl pb-3 text-center dark:text-white')}>{name} {cardType === 'Foil' || cardType === 'Etched' ? `(${cardType})` : '' }</h4>
                <div className={clsx('w-[80%] lg:w-3/4 mx-auto text-center pb-6 md:pb-0')}>
                    {
                        (imageUrls?.length === 0 || !imageUrls) &&
                            <p>
                                No images found
                            </p>
                    }
                    {
                        imageUrls?.length === 1 &&
                            imageUrls?.map((imageUrl, i) => (
                                <img 
                                    key={`card-face-image-image-${name}-${i}`}
                                    className={clsx('mx-auto')}
                                    src={imageUrl} 
                                    alt={`A scanned version of the card ${name}`}
                                />
                            ))
                    }
                    {
                        imageUrls?.length > 1 &&
                            <CarouselWithButtons 
                                id='mtgCardImage'
                                items={
                                    imageUrls.map((imageUrl, i) => (
                                        <img 
                                            key={`card-face-image-image-${name}-${i}`}
                                            className={clsx('mx-auto')}
                                            src={imageUrl} 
                                            alt={`A scanned version of the card ${name}`}
                                        />
                                    ))
                                }
                            />
                    }
                </div>
            </div>
            {
                hasExtras && (
                    <div 
                        className={
                            clsx('flex flex-col pt-1 md:pt-0 md:pl-4 gap-2 md:px-2 md:w-1/2 h-full overflow-y-scroll overflow-x-hidden')
                        }
                    >
                        {
                            hasPriceDetails &&
                                <CollapseMenu
                                    title='Price Detail'
                                >
                                    {
                                        price && <TitledText title='Price'>{price}</TitledText>
                                    }
                                    {
                                        cardType && <TitledText title='Card Type'>{cardType}</TitledText>
                                    }
                                    {
                                        dateBought && <TitledText title='Date Bought'>{dateBought}</TitledText>
                                    }
                                    {
                                        boughtAt && <TitledText title='Bought At'>{boughtAt}</TitledText>
                                    }
                                    {
                                        condition && <TitledText title='Condition'>{condition}</TitledText>
                                    }
                                </CollapseMenu>
                        }
                        {
                            hasCardDetails &&
                                <CollapseMenu
                                    title='Card Detail'
                                >
                                    {
                                        power && toughness && 
                                            <TitledText 
                                                title='Power/Toughness'
                                            >
                                                {power}/{toughness}
                                            </TitledText>
                                    }
                                    {
                                        colors && <TitledText title='Colors'>{colors}</TitledText>
                                    }
                                    {
                                        cmc && <TitledText title='CMC'>{cmc}</TitledText>
                                    }
                                    {
                                        rarity && <TitledText title='Rarity'>{rarity}</TitledText>
                                    }
                                    {
                                        legalities && <TitledText title='Legalities'>{legalities}</TitledText>
                                    }
                                    {
                                        keywords && <TitledText title='Keywords'>{keywords}</TitledText>
                                    }
                                    {
                                        type && <TitledText title='Type'>{type}</TitledText>
                                    }
                                </CollapseMenu>
                        }
                        {
                            hasQuantityDetails &&
                                <CollapseMenu
                                    title='Quantity Detail'
                                >
                                    {
                                        mintQuantity && <TitledText title='Mint Quantity'>{mintQuantity}</TitledText>
                                    }
                                    {
                                        nearMintQuantity && <TitledText title='Near Mint Quantity'>{nearMintQuantity}</TitledText>
                                    }
                                    {
                                        lightlyPlayedQuantity && <TitledText title='Lightly Played Quantity'>{lightlyPlayedQuantity}</TitledText>
                                    }
                                    {
                                        moderatelyPlayedQuantity && <TitledText title='Moderately Played Quantity'>{moderatelyPlayedQuantity}</TitledText>
                                    }
                                    {
                                        heavilyPlayedQuantity && <TitledText title='Heavily Played Quantity'>{heavilyPlayedQuantity}</TitledText>
                                    }
                                    {
                                        damagedQuantity && <TitledText title='Damaged Quantity'>{damagedQuantity}</TitledText>
                                    }
                                </CollapseMenu>
                        }
                    </div>
                )
            }
        </div>   
    )
}

export default MtgCard