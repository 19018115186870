// Queries
import { setMtgCardInventoryEntry } from "../../../graphql/mutations";
import { getMtgCardInventoryEntries } from "../../../graphql/queries";

export const deleteCard = async ({ clients, userId, setCardId }: { clients: any, userId: string, setCardId: string }) => {
    const response = await clients.graphql.graphql({
        query: setMtgCardInventoryEntry,
        variables: {
            input: {
                userId: userId,
                setCardId: setCardId,
                isDeleted: true,
            }
        }
    })

    return response?.data?.setMtgCardInventoryEntry
}

const getCardsFunction = async (nextToken: string, cards: any, userId: string, clients: any): Promise<any> => {
    if (!nextToken) return [ ...cards ]

    let tempToken: any = nextToken
    if (nextToken === 'skip') { tempToken = null }

    const response: any = await clients.graphql.graphql({
        query: getMtgCardInventoryEntries,
        variables: {
            userId: userId,
            limit: 500,
            nextToken: tempToken,
        }
    })

    let currentToken = response?.data?.getMtgCardInventoryEntries?.nextToken
    return getCardsFunction(
        currentToken, [ ...cards, ...response?.data?.getMtgCardInventoryEntries?.data], userId, clients
    )
}

export const getCards = async ({ userId, clients }: { userId: string, clients: any }): Promise<any> => {
    return await getCardsFunction('skip', [], userId, clients)
}