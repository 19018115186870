/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext } from "react";
import { useNavigate, Outlet } from "react-router-dom";
// Contexts
import { UserContext } from "../contexts/user";

const ProtectedLayout = () => {
  // React Router Dom
  const navigate = useNavigate();
  // Contexts
  const [user] = useContext<any>(UserContext);

  useEffect(() => {
    if (user.isLoading || user.isSignedIn) {
      return;
    } else if (!user.isSignedIn) {
      navigate('/login');
      return;
    }
  }, [user]);

  return <Outlet />;
};

export default ProtectedLayout;
