export const stableDiffusionStylePresets = () => {
    return [
        {
            name: '3D Model',
            value: '3d-model'
        },
        {
            name: 'Analog Film',
            value: 'analog-film'
        },
        {
            name: 'Anime',
            value: 'anime'
        },
        {
            name: 'Cinematic',
            value: 'cinematic'
        },
        {
            name: 'Comic Book',
            value: 'comic-book'
        },
        {
            name: 'Digital Art',
            value: 'digital-art'
        },
        {
            name: 'Enhance',
            value: 'enhance'
        },
        {
            name: 'Fantasy Art',
            value: 'fantasy-art'
        },
        {
            name: 'Isometric',
            value: 'isometric'
        },
        {
            name: 'Line Art',
            value: 'line-art'
        },
        {
            name: 'Low Poly',
            value: 'low-poly'
        },
        {
            name: 'Modeling Compound',
            value: 'modeling-compound'
        },
        {
            name: 'Neon Punk',
            value: 'neon-punk'
        },
        {
            name: 'Origami',
            value: 'origami'
        },
        {
            name: 'Photographic',
            value: 'photographic'
        },
        {
            name: 'Pixel Art',
            value: 'pixel-art'
        },
        {
            name: 'Tile Texture',
            value: 'tile-texture'
        },
    ]
}